import Axios from "axios";
import { COMPANY_LIST_FAIL, COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS } from "../constants/companyConstants";
import { serverAddress } from "../constants/dbConstants";

export const getAccessableCompanies = () => async (dispatch) => {
    const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
    const token = user.token;    
    dispatch({
        type: COMPANY_LIST_REQUEST,
    });
    try {
        const { data } = await Axios.get(`${serverAddress}/api/accounts`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
        console.log(data)
        // localStorage.setItem('companyDetails', JSON.stringify(data));
        dispatch({
            type: COMPANY_LIST_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: COMPANY_LIST_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};
