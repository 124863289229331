import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useHistory} from 'react-router-dom'
import { getCompanyInfo } from '../actions/companyActions';
import { getEntries } from '../actions/fileSystemActions';
import { getSettingsInfo, removeFormInfo, removeInvoiceInfo } from '../actions/invoiceActions';
import { pinout, signout } from '../actions/userActions';
import Header from '../components/Header';
import OauthPopup from 'react-oauth-popup';
import { docusignAuth } from '../actions/docusignActions';

function DashboardScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;    
  const dispatch = useDispatch();

  const signoutHandler = () => {
    dispatch(pinout());
    props.history.push('/')
  }
  const token = userInfo.token;
  const companyId = userInfo.companyId;

  useEffect(() => {
    dispatch(getCompanyInfo(token, companyId));
    
  }, [dispatch, token, ]);

  const company = useSelector(state => state.companyDetails);
  const { companyDetails } = company;


  useEffect(() => {
    dispatch(removeFormInfo())
    dispatch(removeInvoiceInfo())
    dispatch(getSettingsInfo());
    
  }, [dispatch]);

  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
  }, [props.history, userInfo, pinCodeLogin]);
  
    useEffect(() => {
        dispatch(getEntries())
    }, []);
  
  const fileSuccess = (files) => {
    console.log(files);
    //  window.open(files[0].link, '_blank');
  }

  if(companyDetails){
    console.log("client ID ==>", companyDetails.dsClientId)
  }
  const SCOPES = 'signature';
  const CLIENT_ID= companyDetails && companyDetails.dsClientID;
  // const CLIENT_ID='fabc4abd-443b-40d8-975e-36de070d826b';
  const redirectUrl = 'http://localhost:3000/docusign_auth/data';
  // const redirectUrl = 'https://myaccounts.webnapp.com.au/docusign_auth/data';
  // const redirectUrl = 'https://webnapp-4135b.web.app/docusign_auth/data';

  const history = useHistory();

const onCode = (code, params) => {
  console.log("wooooo a code", code);
  console.log("alright! the URLSearchParams interface from the popup url", params);
  dispatch(docusignAuth(code));
  history.push('/docusign')
  // window.close()
}

const docuSignAuth = useSelector(state => state.docuSignAuth);


const onClose = () => console.log("closed!");
  return (
    <>   
    <Header />   
    <div id="pages_maincontent" >
      <div className="log">
        <h2 className="page_title" >
          <i className="fa fa-home" aria-hidden="true" />
          &nbsp;Dashboard 
        </h2>
      <i onClick={()=>signoutHandler()} className="fa fa-sign-out" aria-hidden="true"></i>
      </div>
      {userInfo ?
      <h3 id="dvResellerName" >
          Welcome {userInfo.userName?userInfo.userName:'null'}
        </h3> :
          <></>}
        
        {companyDetails &&
          <div className="page_single layout_fullwidth_padding">
          {companyDetails.isAccountingCompany ?
            <Link to="/accounts" className="homepage_button btn btn-primary btn-lg btn-block">
              Companies Folders
            </Link> :
            <>
              <Link to="/create" className="homepage_button btn btn-primary btn-lg btn-block">
                Create New</Link>
              <Link to="/invoices" className="homepage_button btn btn-primary btn-lg btn-block">
                Invoices List
        </Link>
              <Link draggable to="/customers" className="homepage_button btn btn-primary btn-lg btn-block">
                Clients List
        </Link>
              <Link to="/services" className="homepage_button btn btn-primary btn-lg btn-block">
                Services
        </Link>
              {companyDetails && companyDetails.settingsPage &&
                <Link to="/configuration" className="homepage_button btn btn-primary btn-lg btn-block">
                  Configuration
        </Link>}
              <Link to={`/assets/${companyDetails.companyFolder}`} className="homepage_button btn btn-primary btn-lg btn-block">
                Company Folder
        </Link>
        {docuSignAuth?          
          <Link to='/docusign' className="homepage_button btn btn-primary btn-lg btn-block">
              DocuSign
          </Link>:
        <OauthPopup
          url={`https://account-d.docusign.com/oauth/auth?` +
          `response_type=code&` +
          `scope=${SCOPES}&` +
          `client_id=${CLIENT_ID}&` +
          `redirect_uri=${redirectUrl}`}
          onCode={onCode}
          onClose={onClose}
        >
          <Link  className="homepage_button btn btn-primary btn-lg btn-block">
                DocuSign
        </Link>
        </OauthPopup>}
        <Link to="/setting" className="homepage_button btn btn-primary btn-lg btn-block">
                Account Setting
        </Link>
        {/* <Link onClick={()=>authHandler()} className="homepage_button btn btn-primary btn-lg btn-block">
                DocuSign
        </Link> */}
            </>}
          </div>} 
      </div>
    </>
    );
  }
export default DashboardScreen;