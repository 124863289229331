import React from "react";
import { serverAddress } from "../../constants/dbConstants";

function TemplateOne(props) {
  let { companyDetails, invoice, foot, balanceAmount } = props;
  return (
    <div>
      <section
        id="memo"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={
              companyDetails.logoType == "url"
                ? companyDetails.logoUrl
                : `${serverAddress}/${companyDetails.logoUrl}`
            }
            width="200"
            alt="logo"
          />
        </div>
        <div
          style={{
            maxWidth: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            whiteSpace: "pre",
          }}
        >
          {companyDetails.phone != "" && (
            <span
              style={{
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              <b>Phone: </b>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontFamily: "sans-serif",
                }}
              >
                {companyDetails.phone}
              </span>
            </span>
          )}
          {companyDetails.email != "" && (
            <span
              style={{
                fontSize: "14px",
                fontFamily: "sans-serif",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "pre",
              }}
            >
              <b>Email: </b>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontFamily: "sans-serif",
                }}
              >
                {companyDetails.email}
              </span>
            </span>
          )}
          {companyDetails.website != "" && (
            <span
              style={{
                fontSize: "14px",
                fontFamily: "sans-serif",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <b>Website: </b>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontFamily: "sans-serif",
                }}
              >
                {companyDetails.website}
              </span>
            </span>
          )}
          {companyDetails.ABN != "" && (
            <span
              style={{
                fontSize: "14px",
                fontFamily: "sans-serif",
              }}
            >
              <b>ABN: </b>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontFamily: "sans-serif",
                }}
              >
                {companyDetails.ABN}
              </span>
            </span>
          )}
        </div>
      </section>
      <hr
        style={{
          backgroundColor: "#000",
          height: "4px",
          fontFamily: "sans-serif",
        }}
      />
      <div
        className="clearfix"
        style={{
          display: "block",
        }}
      />

      <div
        className="inv-details"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <section
          className="invoice-section section"
          style={{
            paddingTop: "20px",
            margin: 0,
            fontFamily: "sans-serif",
          }}
        >
          <div
            className="first"
            style={{
              display: "block",
              color: "#000",
              fontSize: "40px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
            }}
          >
            {invoice.selectedInvoice.quotation === "Yes"
              ? "Quotation"
              : invoice.selectedInvoice.invoice === "Yes"
              ? "Invoice"
              : "Receipt"}
          </div>

          <div className="second">
            <span
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontFamily: "sans-serif",
                fontWeight: "500",
              }}
            >
              <b>Date: </b>
              <span
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  fontWeight: "400",
                }}
              >
                {invoice.selectedInvoice.quotation === "Yes"
                  ? invoice.selectedInvoice.quotationDate
                  : invoice.selectedInvoice.invoice === "Yes"
                  ? invoice.selectedInvoice.invoiceDate
                  : invoice.selectedInvoice.receipt === "Yes"
                  ? invoice.selectedInvoice.receiptDate
                  : "N/A"}
              </span>
            </span>
          </div>

          <div className="second">
            <span
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "sans-serif",
              }}
            >
              <b>
                {invoice.selectedInvoice.quotation === "Yes"
                  ? "Quotation"
                  : invoice.selectedInvoice.invoice === "Yes"
                  ? "Invoice"
                  : "Receipt"}
                :{" "}
              </b>
              <span
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "sans-serif",
                }}
              >
                {" "}
                {invoice.selectedInvoice.number}
              </span>
            </span>
          </div>
        </section>

        <section
          className="invoice-section section"
          style={{
            paddingTop: "20px",
            margin: 0,
            fontFamily: "sans-serif",
          }}
        >
          {invoice.selectedInvoice ? (
            <>
              <div
                className="first"
                style={{
                  display: "block",
                  color: "#FB5859",
                  fontSize: "24px",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                Customer Details
              </div>
              {/* <div>
                          <span className="second" style={{
                            textAlign: "left",
                            fontSize: "14px",
                            fontFamily: "sans-serif"
                          }}>
                            <b>Company Name:</b>
                            <span style={{
                              textAlign: "left",
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "sans-serif"
                            }}>
                              {invoice.selectedInvoice.companyName}
                            </span>                           
                          </span>                         
                        </div> */}

              {invoice.selectedInvoice.clientName ? (
                <div>
                  <span
                    className="second"
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    <b>Name: </b>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {invoice.selectedInvoice.clientName}
                      {invoice.selectedInvoice.companyName
                        ? ` ( ${invoice.selectedInvoice.companyName} )`
                        : ""}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}

              {invoice.selectedInvoice.email ? (
                <div>
                  <span
                    className="second"
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <b>Email: </b>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {invoice.selectedInvoice.email}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}

              {invoice.selectedInvoice.phone ? (
                <div>
                  <span
                    className="second"
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    <b>Phone: </b>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {invoice.selectedInvoice.phone}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}

              {invoice.selectedInvoice.ABN ? (
                <div>
                  <span
                    className="second"
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <b>ABN: </b>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "500",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {invoice.selectedInvoice.ABN}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </section>
      </div>

      <div className="clearfix" />

      <div className="below">
        <div
          style={{
            width: "100%",
            display: "inline",
            color: "858585",
            fontWeight: "bold",
            fontSize: "12px",
            fontFamily: "sans-serif",
            fontStyle: "italic",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              float: "right",
              marginRight: "110px",
              color: "gray",
              fontSize: "10px",
              margin: "0px",
              marginTop: "20px",
              fontFamily: "sans-serif",
            }}
          >
            prices are in AUD
          </span>
        </div>
      </div>

      <section
        className="items"
        style={{
          width: "100%",
          marginTop: "25px",
          fontFamily: "sans-serif",
        }}
      >
        <table
          id="tblSearch"
          style={{
            width: "100%",
            fontFamily: "sans-serif",
          }}
          className="table table-hover nowrap"
        >
          <tbody>
            <tr
              style={{
                backgroundColor: props.themeColor,
                fontWeight: "bolder",
                padding: "10px",
                fontFamily: "sans-serif",
              }}
            >
              <td
                className="first"
                style={{
                  backgroundColor: props.themeColor,
                  fontWeight: "bold",
                  padding: "10px 10px",
                  fontFamily: "sans-serif",
                }}
              >
                Description
              </td>
              <td
                className="second"
                style={{
                  backgroundColor: props.themeColor,
                  fontWeight: "bold",
                  padding: "10px 10px",
                  fontFamily: "sans-serif",
                  textAlign: "right",
                }}
              >
                Price
              </td>

              <td
                className="second"
                style={{
                  backgroundColor: props.themeColor,
                  fontWeight: "bold",
                  padding: "10px 10px",
                  fontFamily: "sans-serif",
                  textAlign: "right",
                }}
              >
                Total
              </td>
            </tr>
          </tbody>

          <tbody>
            {invoice.selectedInvoice.listItems.map((v, i) => (
              <tr key={i}>
                <td
                  className="first"
                  dangerouslySetInnerHTML={{
                    __html: `${v.longDescription.replaceAll(/\n/g, "<br/>")}`,
                  }}
                >
                  {/* {v.longDescription} */}
                </td>
                {v.price !== 0 ? (
                  <>
                    <td
                      className="second"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        whiteSpace: "nowrap",
                        textAlign: "right",
                      }}
                    >
                      {/* ${parseFloat(v.price).toFixed(2)} x{v.qty} */}
                      {Number(v.price) && v.price !== 0 ? (
                        <b>
                          ${parseFloat(v.price).toFixed(2)} x {v.qty}
                        </b>
                      ) : (
                        ""
                      )}
                    </td>
                    <td
                      className="second"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        whiteSpace: "nowrap",
                        textAlign: "right",
                      }}
                    >
                      {/* ${parseFloat(v.price).toFixed(2)} x{v.qty} */}
                      {Number(v.price) && v.price !== 0 ? (
                        <b>${parseFloat(v.price * v.qty).toFixed(2)}</b>
                      ) : (
                        ""
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <section
        className="sums"
        style={{
          marginTop: "40px",
          fontFamily: "sans-serif",
        }}
      >
        <table
          className="first1"
          style={{
            float: "right",
            width: "40%",
            backgroundColor: props.themeColor,
            fontFamily: "sans-serif",
            paddingLeft: "10px",
          }}
        >
          <tbody className="mob">
            {invoice.selectedInvoice.gstType === "excluded" && (
              <>
                <tr id="ctl04_gstpanel" className="amount-total">
                  <td
                    style={{
                      float: "right",
                      fontSize: "23px",
                      fontWeight: "500",
                      fontFamily: "sans-serif",
                      backgroundColor: props.themeColor,
                    }}
                    className="second"
                  >
                    Sub-Total: $
                    <span id="ctl04_lblGSTAmount">
                      {parseFloat(invoice.selectedInvoice.subTotal).toFixed(2)}
                    </span>
                  </td>
                </tr>
                <tr id="ctl04_gstpanel" className="amount-total">
                  <td
                    style={{
                      float: "right",
                      fontSize: "23px",
                      fontWeight: "500",
                      fontFamily: "sans-serif",
                      backgroundColor: props.themeColor,
                    }}
                    className="second"
                  >
                    GST (10%): $
                    <span id="ctl04_lblGSTAmount">
                      {parseFloat(invoice.selectedInvoice.gst).toFixed(2)}
                    </span>
                  </td>
                </tr>{" "}
              </>
            )}

            {
              invoice.selectedInvoice.gstType === "no" && <></>
              // <tr id="ctl04_gstpanel" className="amount-total">
              //   <td style={{
              //     float: "right",
              //     fontSize: "23px",
              //     fontWeight: "500",
              //     fontFamily: "sans-serif",
              //     backgroundColor: props.themeColor
              //   }} className="second">Not Registered for GST: $<span id="ctl04_lblGSTAmount">
              //       0
              //     </span>
              //   </td>
              // </tr>
            }

            {invoice.selectedInvoice.gstType === "included" && (
              <tr id="ctl04_gstpanel" className="amount-total">
                <td
                  style={{
                    float: "right",
                    fontSize: "23px",
                    fontWeight: "500",
                    fontFamily: "sans-serif",
                    backgroundColor: props.themeColor,
                  }}
                  className="second"
                >
                  {" "}
                  GST Included (10%): $
                  <span id="ctl04_lblGSTAmount">
                    {parseFloat(invoice.selectedInvoice.gst).toFixed(2)}
                  </span>
                </td>
              </tr>
            )}

            <tr className="amount-total mob">
              <td
                className="second"
                style={{
                  float: "right",
                  fontSize: "23px",
                  fontFamily: "sans-serif",
                  fontWeight: "500",
                  backgroundColor: props.themeColor,
                }}
              >
                Total: $
                <span id="ctl04_lblTotalAmount">
                  {parseFloat(invoice.selectedInvoice.totalAmount).toFixed(2)}
                </span>
              </td>
            </tr>

            <tr className="amount-total">
              <td
                style={{
                  float: "right",
                  fontFamily: "sans-serif",
                  backgroundColor: props.themeColor,
                }}
                className="third"
              >
                Amount Paid: $
                <span id="ctl04_lblPaidAmount">
                  {parseFloat(invoice.selectedInvoice.paidAmount).toFixed(2)}
                </span>
              </td>
            </tr>
            <tr className="amount-total">
              <td
                style={{
                  float: "right",
                  fontFamily: "sans-serif",
                  backgroundColor: props.themeColor,
                }}
                className="third"
              >
                Balance Amount: $
                <span id="ctl04_lblBalanceAmount">
                  {parseFloat(balanceAmount).toFixed(2)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="payment-info"
          dangerouslySetInnerHTML={{ __html: foot }}
        />
      </section>
    </div>
  );
}

export default TemplateOne;
