import React, { useEffect, useState } from 'react'
import ViewFiles from './ViewFiles'
import Sidebar from '../../components/FileSystem/Sidebar'
import { selectPath, getEntries, selectDirectory, uploadFile, uploadFolder } from '../../actions/fileSystemActions';
import { useDispatch, useSelector } from 'react-redux';
import Loadingbox from "../../components/LoadingBox";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { serverAddress } from '../../constants/dbConstants';
import PinCodeModal from '../../components/Modal/PinCodeModal';
import {DropzoneDialog} from 'material-ui-dropzone'


function FilesScreen(props) {

    const path = props.match.params.id;

    const dispatch = useDispatch();

    const fileSystem = useSelector(state => state.fileSystem);
    const { loading, error, list, currentPath, currentDirectory, treeArray } = fileSystem;
    const [dirTree, setDirTree] = useState(treeArray ? treeArray : []);
    const [index, setIndex] = useState(0);
    const [dropDown, setDropDown] = useState(false);
    const [fileUpload, setFileUpload] = useState(false);
    const [folderUpload, setFolderUpload] = useState(false);
    const [uploadData, setUploadData] = useState();
    const [folderName, setFolderName] = useState('');

    console.log("upload data==>", uploadData)

    const fileUploadHandler = () => {
        
    }

    const folderUploadHandler = () => {
        const pathArr = currentPath.split("/");
        let tempPath = `${pathArr[1]}`;
        console.log("pathArr==> ", pathArr);
        for (let i = 2; i < pathArr.length; i++) {
            tempPath = `${tempPath}&${pathArr[i]}`;
            // if(tempPath!=)
        }
        dispatch(uploadFolder(folderName, tempPath, currentPath));
        setTimeout(() => {
            console.log("dir==>", currentDirectory)
            // dispatch(getEntries())
            let tempDirectory = currentDirectory;
            let tempChildren = currentDirectory.children;
            tempChildren.push({
                children: [],
                name: folderName,
                path: `${currentPath}/${folderName}`,
                size: 0,
                type: "directory"})
            tempDirectory.children = tempChildren;
            dispatch(selectDirectory(tempDirectory))
            setFolderUpload(false);
            setFolderName('')
        }, 1000);
    }
    const handleImageUpload = event => {
        const files = event.target.files;

        const pathArr = currentPath.split("/");
        let tempPath = `${pathArr[1]}`;
        console.log("pathArr==> ", pathArr);
        for (let i = 2; i < pathArr.length; i++) {
            tempPath = `${tempPath}&${pathArr[i]}`;
            // if(tempPath!=)
        }
        // let dataObj = files[0];
        // dataObj.path = currentPath;
        // console.log("data Obj==>", dataObj);

        const formData = new FormData()
        formData.append('myFile', files[0]);
        // formData.append('path', { currentPath });

        // fetch(`${serverAddress}/api/assets/upload`, {
        //     method: 'POST',
        //     body: formData
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data.path)
        //     })
        //     .catch(error => {
        //         console.error(error)
        //     });
        
        dispatch(uploadFile(formData, tempPath, currentPath));
        setTimeout(() => {
            console.log("dir==>", currentDirectory)
            // dispatch(getEntries())
            let tempDirectory = currentDirectory;
            let tempChildren = currentDirectory.children;
            tempChildren.push({
                children: [],
                name: files[0].name,
                path: `${currentPath}/${files[0].name}`,
                size: 0,
                type: "file"})
            tempDirectory.children = tempChildren;
            dispatch(selectDirectory(tempDirectory))
            // dispatch(getEntries())
            setFileUpload(false)
        }, 1000);
    }

    useEffect(() => {
        dispatch(getEntries(path));
    }, []);

    useEffect(() => {
        if (list) {
            dispatch(selectPath(list.path));
        }
    }, [list]);

    useEffect(() => {
        if (list && currentPath) {
            let pathArr = currentPath.split('/');
            let tempPath = `assets/${pathArr[1]}`;
            let tempDir = list;
            console.log("path Array==>", pathArr);
            for (let i = 2; i < pathArr.length; i++){
                tempPath = `${tempPath}/${pathArr[i]}`;
                // if(tempPath!=)
                tempDir=tempDir.children.filter(x => x.path === tempPath)[0]
                console.log("tempPath==>", tempPath);
            }
            console.log("temp dir==>", tempDir);
            dispatch(selectDirectory(tempDir));

        }
        if (list) {
            let currentDir = {};
            let parentDir = {};
            if (currentPath === list.path) {
                parentDir = list;
                currentDir = list;
                dispatch(selectDirectory(currentDir));
            } 

        }

    }, [currentPath, list]);

    // let currentDir = list && list.children.filter(x => x.path === 'assets\\UNICORN_CONSULTANTS\\test');
    // console.log("current Directory==>",currentDir[0])

    return (
        <>
        <div className="file-sytem-main">
        {loading && <Loadingbox />}
        { list && currentDirectory &&
            <>
                {/* <Sidebar tree={list} /> */}
                <ViewFiles tree={currentDirectory} />
            </>}
            {/* <button class="file-add-btn">
                <i class="fas fa-plus-circle"></i>
            </button> */}

                      {/* <button class="dropbtn">Right</button> */}
                    {/* <i onClick={() => { editCustomer(i); setEditFlag(true); }} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" /> */}
                    {/* {dropDown ?
                        <button class="file-add-btn">
                            <i onClick={() => setDropDown(false)}
                                className="fas fa-times" aria-hidden="true"
                            />
                        </button>
                        :
                        <button class="file-add-btn">
                            <i onClick={() => setDropDown(true)}
                                className="fas fa-plus-circle dropbtn" aria-hidden="true"
                            />
                        </button>} */}
            {/* <div className="actions">
                <div class="dropdown">
                    <div class="dropdown-content file-system"
                        style={dropDown ? { display: 'block' } : { display: 'none' }}>
                        <Link onClick={() => { setFileUpload(true); setFolderUpload(false); setDropDown(false) }}>File Upload</Link>
                        <Link onClick={() => { setFileUpload(false); setFolderUpload(true); setDropDown(false) }}>Create Folder</Link>
                        <Link>Info</Link>
                    </div>
                </div>
            </div> */}

            {fileUpload && <div class="dropdown-content file-system uploader"
                style={fileUpload ? { display: 'block' } : { display: 'none' }}>
                <h3 className="head">Upload a File</h3>
                <div className="input">
                    <label>Choose a file :</label>
                    <input type="file" onChange={(e) => handleImageUpload(e)} />
                </div>
                <div className="foot">
                    <button onClick={() => setFileUpload(false)}>Close</button>
                </div>
            </div>}

            {folderUpload && <div class="dropdown-content file-system uploader"
                style={fileUpload ? { display: 'block' } : { display: 'none' }}>
                <h3 className="head">Create a Folder</h3>
                <div className="input">
                    <label>Folder Name :</label>
                    <input type="text" onChange={(e) => setFolderName(e.target.value)} value={folderName} />
                </div>
                <div className="foot">
                    <button onClick={() => folderUploadHandler()}>Create</button>
                    <button onClick={() => setFolderUpload(false)}>Close</button>
                </div>
            </div>}
            {/* <PinCodeModal modalStatus={true} ></PinCodeModal> */}
                            {/* <DropzoneDialog
                    open={true}
                    // onSave={this.handleSave.bind(this)}
                    // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    showPreviews={true}
                    maxFileSize={5000000}
                    // onClose={this.handleClose.bind(this)}
                /> */}
<div style={{position: 'relative',color:'black', width: '100vw', height: '5vh'}}>
            {/* <div className="actions"> */}
                    <div class="dropdown">
                        <div class="dropdown-content file-system"
                            style={dropDown ? { display: 'block' } : { display: 'none' }}>
                            <Link onClick={() => { setFileUpload(true); setFolderUpload(false); setDropDown(false) }}>File Upload</Link>
                            <Link onClick={() => { setFileUpload(false); setFolderUpload(true); setDropDown(false) }}>Create Folder</Link>
                            <Link>Info</Link>
                        </div>
                    </div>
                {/* </div> */}
            {dropDown ?
                <button class="file-add-btn">
                    <i onClick={() => setDropDown(false)}
                        className="fas fa-times" aria-hidden="true"
                    />
                </button>
                :
                <button class="file-add-btn">
                    <i onClick={() => setDropDown(true)}
                        className="fas fa-plus-circle dropbtn" aria-hidden="true"
                    />
                </button>}
            </div>
        </div>
        
            </>
    )
}

export default FilesScreen
