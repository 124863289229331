import React from 'react'

import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { serverAddress } from "../../constants/dbConstants";
import { getAccessableCompanies } from '../../actions/accountingCompanyActions';
import Header from '../../components/Header';
import Loadingbox from '../../components/LoadingBox';

function AccountsScreen(props) {
  const [selectedClient, setSelectedClient] = useState({});
  const [editFlag, setEditFlag] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;

    const accessableCompanies = useSelector(state => state.accessableCompanies);
    const { companies } = accessableCompanies;

    const company = useSelector(state => state.companyDetails);
    const { companyDetails } = company;
    
  const [dropDown, setDropDown] = useState('');
  
  const dispatch = useDispatch();
    useEffect(() => {
        if (companyDetails) {   
            if (companyDetails.isAccountingCompany) {
                dispatch(getAccessableCompanies());
            } else {
                props.history.push('/dashboard')
            }
        }
  }, [dispatch]);

  // useEffect(() => {
  //   if (!pinCodeLogin) {
  //     props.history.push('/');
  //   }
  //   }, [props.history, userInfo, pinCodeLogin]);

  const resetHandler = async (id) => {
    if (window.confirm("Are you sure, you want to reset Pincode ?")) {
          try {
        const { data } = await Axios.get(`${serverAddress}/api/companies/pincode/${id}`);
            alert("Pincode reset Successful");
     } catch (err) {
            alert("Error reseting Pincode");
    }
    }
  }

  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Companies
                </h2>
                <h3 id="dvResellerName" >
          Select Company
                </h3>
        
        <div className="page_single layout_fullwidth_padding">
          {accessableCompanies && companies?
          <ul className="list">
              {companies.map((v, i) =>
                <li key={i} onClick={()=>props.history.push(`/assets/${v.companyFolder}`)}>
                  <div className="details">
                    <div><b>{v.companyName} </b></div>
                    <div>
                      <span className="first">
                        {v.phone}
                      </span>
                      <span>
                        {v.email}
                      </span>
                    </div>
                  </div>
                </li>
              )}
                    
            </ul> : <Loadingbox />}
                </div>
      </div>
      </>
    )
}

export default AccountsScreen
