import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newservice, serviceList } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

function ServicesScreen(props) {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin; 

  const servicesInfo = useSelector((state) => state.serviceInfo);
  const { loading, servicesList } = servicesInfo; 


  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [price, setPrice] = useState('');
  const [messageFlag, setMessageFlag] = useState(false);

  const dispatch = useDispatch();

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(newservice(shortDescription, longDescription, price));
    setTimeout(()=>{setMessageFlag(true)},1000)
    setShortDescription('');
    setLongDescription('');
    setPrice('');
    setTimeout(()=>{setMessageFlag(false)},3000)
  }

  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  // const setPriceFloat = (value) => {
  //   let tempPrice = parseFloat(value).toFixed(2);
  //   console.log(tempPrice);
  //   setPrice(tempPrice);
  // }

  const editService = (id) => {
    props.history.push(`/services/edit/${id}`)
  }
  return (
    <>
      <Header />
    <div id="pages_maincontent">
        <h2 className="page_title" >  
        <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
        <i className="fa fa-plus" aria-hidden="true" />
          &nbsp;Invoice Services:
          </h2>
      <h3 id="dvResellerName">
          See Our services
      </h3>
        <div className="page_single layout_fullwidth_padding">
        
      <div className="container-fluid1 container-fluid1">     
          <div className="row">
            {messageFlag ?
              <MessageBox variant='success'>Service Added</MessageBox> : <></>}
            
          <div className="col-md-12 ">
            {servicesList ?
              <>
                <ul className=" list1">
              
              {servicesList.map((v, i) =>
                <li   className="list1" key={i}>

                <div className="details details1" >
                    <div><b>{v.shortDescription? v.shortDescription: ''} {' '}</b>
                    {v.price ?
                      <b>( ${parseFloat(v.price).toFixed(2)} )</b> : <></>}</div>
                  <div className="space" >
                      {v.longDescription?v.longDescription:''}
                    </div>
                </div>
                  <div className="actions">
                    <i onClick={() => { editService(v._id); }} className="fas fa-edit" aria-hidden="true" />
                </div>
              </li>
              )}        
            </ul></>
                : <></>}
              
              {loading ? <Loadingbox /> :
                <form onSubmit={submitHandler}>
                  <div className="form-group form-group1">
                    <label htmlFor="exampleFormControlTextarea1"><b>Service Short Description: ( For selection )</b></label>
                    <textarea required value={shortDescription} onChange={(e) => setShortDescription(e.target.value)}
                      placeholder="Service Short Description" className="form-control" id="exampleFormControlTextarea1" rows={3} />
                  </div>
                  <div className="form-group form-group1">
                    <label htmlFor="exampleFormControlTextarea1"><b>Service Long Description: ( For invoicing )</b></label>
                    <textarea value={longDescription} onChange={(e) => setLongDescription(e.target.value)}
                      placeholder="Service Long Description" className="form-control" id="exampleFormControlTextarea1" rows={3} />
                  </div>
                  <div className="form-group form-group1">
                    <label htmlFor="exampleInputEmail1"><b>Service Price:</b></label>
                    <input value={price} onChange={(e) => setPrice(e.target.value)}
                      type="number" placeholder="Price" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  <button type="submit" className="btn btn-primary">Add Service</button>
                </form>}
          </div>          
        </div>        
          </div>
      </div>    
      </div>
      </>
    );
  }
export default ServicesScreen;