import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BitlyClient } from "bitly-react";
import { Link, useHistory } from "react-router-dom";
import "./style.css";
import { removeEmailData, sendEmailBackend } from "../../actions/emailActions";
import {
  saveFilePDF,
  selectInvoice,
  updatePaymentDetails,
} from "../../actions/invoiceActions";
import StripeCard from "../../components/StripeCard/StripeCard";
import TemplateOne from "../../components/InvoiceTemplates/TemplateOne";
import TemplateTwo from "../../components/InvoiceTemplates/TemplateTwo";
import Loadingbox from "../../components/LoadingBox";
import MessageBox from "../../components/MessageBox";
import Header from "../../components/Header";
import { serverAddress } from "../../constants/dbConstants";
import { sendSMSAPI, removeSmsData } from "../../actions/smsActions";

function GenerateInvoice(props) {
  const company = useSelector((state) => state.companyDetails);
  const { companyDetails } = company;

  const invoice = useSelector((state) => state.invoiceInfo);
  const settings = useSelector((state) => state.settingsInfo.settings);

  const pdfLink = useSelector((state) => state.pdfLink);
  const { pdfUrl } = pdfLink;

  const [smsModal, setSmsModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [bitlyUrl, setBitlyUrl] = useState("");

  const [emailTemplate, setEmailTemplate] = useState(settings.emailTemplate);
  const [smsTemplate, setSmsTemplate] = useState(settings.smsTemplate);
  console.log("sms template", smsTemplate);

  const [paymentList, setPaymentList] = useState(
    invoice.selectedInvoice ? invoice.selectedInvoice.paymentList : []
  );
  const [payMethod, setPayMethod] = useState("");
  const [payDate, setPayDate] = useState("");
  const [stripePayment, SetStripePayment] = useState("");
  const [pdfState, setPdfState] = useState("");
  const [fileUrl, setFileUrl] = useState(
    `${serverAddress}${invoice.selectedInvoice.url}`
  );
  const [dropBoxUrl, setDropBoxUrl] = useState(null);

  const [balanceFlag, setBalanceFlag] = useState(true);

  const emailInfo = useSelector((state) => state.emailReducer);
  const { emailData } = emailInfo;

  const smsInfo = useSelector((state) => state.smsReducer);
  const { smsData } = smsInfo;

  const dispatch = useDispatch();

  let balanceAmount = invoice.selectedInvoice
    ? invoice.selectedInvoice.balanceAmount
    : 0;

  useEffect(() => {
    if (balanceAmount > 0) {
      setBalanceFlag(true);
    } else {
      setBalanceFlag(false);
    }
  }, [balanceAmount, balanceFlag]);

  const [payValue, setPayValue] = useState(balanceAmount);

  const foot = settings.invoiceFooter;
  let fileName = `${invoice.selectedInvoice.number}`;
  console.log("bitly url", bitlyUrl);
  const bitly = new BitlyClient("930b46de2b827c05809757b390d38b7ed5d5613b", {});
  const generateUrl = async (url) => {
    let result;
    try {
      result = await bitly.shorten(url);
    } catch (e) {
      console.log("error", e);
      throw e;
    }
    setBitlyUrl(result.url);
    return result;
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(bitlyUrl);
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied !";
  };

  const outFunc = () => {
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copy to clipboard";
  };

  const updatePayment = (e) => {
    e.preventDefault();
    const date = GetFormattedDate();
    setPayDate(date);
    setPaymentList((oldArray) => [
      ...oldArray,
      { payMethod, payValue, payDate },
    ]);
  };

  const generatePDF = useCallback(() => {
    setPdfState("loading");
    var doc = document.getElementById("printBox");
    var Source = new XMLSerializer().serializeToString(doc);
    // var Source = '<div></div>';
    fetch("https://v2018.api2pdf.com/chrome/html", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "69d65ee9-6038-4562-9cb8-bf4d833be2ba", //Get your API key from https://portal.api2pdf.com
        Authorization: "69d65ee9-6038-4562-9cb8-bf4d833be2ba", //Get your API key from https://portal.api2pdf.com
      },
      body: JSON.stringify({
        html: Source,
        inlinePdf: true,
        fileName: `${fileName}.pdf`,
        options: {
          displayHeaderFooter: true,
          footerTemplate:
            '<div class="page-footer" style="width:95%; text-align:right; font-size:9px;">Page <span class="pageNumber"></span> of <span class="totalPages"></span></div>',
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.pdf);
        dispatch(
          saveFilePDF(
            res.pdf,
            invoice.selectedInvoice.number,
            companyDetails.companyName,
            invoice.selectedInvoice._id,
            companyDetails.companyFolder
          )
        );
        setPdfState("success");
        generateUrl(fileUrl);
        // window.open(res.pdf, '_blank');
      })
      .catch((err) => {
        setPdfState(err);
      });
  }, []);

  useEffect(() => {
    if (invoice.selectedInvoice) {
      if (
        paymentList.length >= 0 &&
        payMethod !== "" &&
        payValue !== "" &&
        payDate !== ""
      ) {
        dispatch(
          updatePaymentDetails(
            invoice.selectedInvoice._id,
            payMethod,
            payValue,
            payDate
          )
        );
      }
    }
  }, [dispatch, paymentList]);

  useEffect(() => {
    console.log("invoice.selectedInvoice =>");
    console.log(invoice.selectedInvoice);
  }, [invoice.selectedInvoice]);

  useEffect(() => {
    if (invoice.selectedInvoice.url === "") {
      if (!pdfUrl) {
        generatePDF();
      }
    } else {
      setFileUrl(`${serverAddress}${invoice.selectedInvoice.url}`);
    }
  }, [generatePDF, invoice.selectedInvoice.url, pdfUrl]);

  useEffect(() => {
    if (!fileUrl === "") {
      generateUrl(fileUrl);
    }
  }, [fileUrl, generateUrl]);

  useEffect(() => {
    generateUrl(fileUrl);
  }, [fileUrl]);

  useEffect(() => {
    if (pdfUrl && invoice.selectedInvoice.url == "") {
      setFileUrl(`${serverAddress}${pdfUrl}`);
    }
  }, [pdfUrl]);
  console.log("file url==>", fileUrl);
  useEffect(() => {
    setSmsTemplate(
      `${settings.smsTemplate} \n\nHere is the download link : ${bitlyUrl}`
    );
  }, [bitlyUrl]);

  // console.log(dropBoxUrl);
  const GetFormattedDate = () => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    var hours = todayTime.getHours();
    var minutes = todayTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return day + "/" + month + "/" + year + " , " + strTime;
  };

  const receiver = invoice.selectedInvoice ? invoice.selectedInvoice.email : "";
  // const receiver = 'shayanali4@live.com';

  const sendEmail = () => {
    let emailAttachmentName;
    let companySplit = companyDetails.companyName.toUpperCase().split(" ");
    for (let i = 0; i < companySplit.length; i++) {
      if (i === 0) {
        emailAttachmentName = `${companySplit[i]}`;
      } else {
        emailAttachmentName = `${emailAttachmentName}_${companySplit[i]}`;
      }
    }
    emailAttachmentName = `${emailAttachmentName}-${invoice.selectedInvoice.number}`;
    let subject = `${
      invoice.selectedInvoice.quotation === "Yes"
        ? "Invoice"
        : invoice.selectedInvoice.invoice === "Yes"
        ? "Quotation"
        : "Receipt"
    } from ${companyDetails.companyName}`;

    dispatch(
      sendEmailBackend(
        receiver,
        subject,
        emailTemplate,
        fileUrl,
        emailAttachmentName
      )
    );
    // setEmailModal(false);
    // alert(`Email sent to ${receiver}`);
  };

  if (emailInfo.emailData === "Email Sent") {
    alert(`Email sent to ${receiver}`);
    setTimeout(() => {
      setEmailModal(false);
    }, 1000);
    dispatch(removeEmailData());
  }

  if (smsInfo.smsData) {
    alert(`SMS sent to ${invoice.selectedInvoice.phone}`);
    setTimeout(() => {
      setSmsModal(false);
    }, 1000);
    dispatch(removeSmsData());
  }

  const downloadPDF = (e) => {
    window.open(fileUrl, "_blank");
  };

  const copySMS = (e) => {
    let textarea = document.getElementById("smsText");
    textarea.select();
    document.execCommand("copy");
    setSmsModal(false);
    alert("SMS Copied Successfully ");
  };

  const sendSMS = () => {
    const username = "shaikhsiddiqui";
    const password = "p5e3cMnb";
    const message = document.getElementById("smsText").value;
    const to = invoice.selectedInvoice.phone;
    const from = "WEBNAPP";
    dispatch(sendSMSAPI(username, password, to, from, message));
  };

  const history = useHistory();

  const editHandler = () => {
    props.history.push(`/invoice/${invoice.selectedInvoice._id}`);
  };

  const backToDashboard = () => {
    history.push("/dashboard");
  };
  return (
    <>
      {/* <Header /> */}

      <div id="ctl04_dvpanel" className="container-fluid tab-pane">
        <div className="second">
          {invoice.selectedInvoice ? (
            <div className="dvMainInvoice">
              <div
                id="printBox"
                style={{
                  fontFamily: "Calibri",
                }}
              >
                <TemplateOne
                  companyDetails={companyDetails}
                  invoice={invoice}
                  foot={foot}
                  balanceAmount={balanceAmount}
                  themeColor={settings.themeColor}
                />
                {/* <TemplateTwo companyDetails={companyDetails} invoice={invoice}
                  foot={foot} balanceAmount={balanceAmount} /> */}
              </div>

              <br />

              <>
                {pdfState === "loading" ? (
                  <Loadingbox>Generating Pdf, please wait...</Loadingbox>
                ) : pdfState === "error" ? (
                  <MessageBox variant="danger">Error Generating Pdf</MessageBox>
                ) : (
                  <>
                    <Link
                      onClick={() => downloadPDF()}
                      className="download-btn"
                    >
                      CLICK HERE TO DOWNLOAD YOUR INVOICE
                    </Link>
                    {/* {fileUrl === '' ? <></> :
                        <DropBox fileName={`${invoice.selectedInvoice.number}.pdf`}
                          fileURL={fileUrl} />} */}
                    <br />
                    <br />
                    <Link
                      onClick={() => generatePDF()}
                      className="download-btn"
                    >
                      Regenerate PDF
                    </Link>
                  </>
                )}
              </>
              <br />
              <br />
              {balanceFlag ? (
                <section>
                  {settings.stripeKey !== "" && (
                    <div className="paywithstripe">
                      <p>Would you like to pay using your Debit/Credit Card?</p>
                      <div>
                        <span>
                          <input
                            onChange={(e) => SetStripePayment(e.target.value)}
                            id="pay-yes"
                            type="radio"
                            name="pay-now"
                            value="yes"
                          />
                          <label htmlFor="pay-yes">Yes</label>
                        </span>
                        <span>
                          <input
                            onChange={(e) => SetStripePayment(e.target.value)}
                            defaultChecked
                            id="pay-no"
                            type="radio"
                            name="pay-now"
                            value="no"
                          />
                          <label htmlFor="pay-no">No</label>
                        </span>
                      </div>
                    </div>
                  )}

                  {stripePayment === "yes" ? (
                    <StripeCard
                      pay={parseFloat(balanceAmount).toFixed(2)}
                      email={invoice.selectedInvoice.email}
                      paidAmount={invoice.selectedInvoice.paidAmount}
                    />
                  ) : (
                    <></>
                  )}
                </section>
              ) : (
                <></>
              )}
              <br />
              <section className="admin-use">
                <h4>For Admin Use Only</h4>
                <p>Send directly to your customer</p>
                <div className="send">
                  <button
                    onClick={() => setSmsModal(true)}
                    type="button"
                    className="btn btn-info btn-lg"
                    data-toggle="modal"
                    data-target="#smsModal"
                  >
                    Send SMS
                  </button>
                  <button
                    onClick={() => setEmailModal(true)}
                    type="button"
                    className="btn btn-info btn-lg"
                    data-toggle="modal"
                    data-target="#emailModal"
                  >
                    Send Email
                  </button>
                  <button
                    onClick={() => setPaymentModal(true)}
                    type="button"
                    className="btn btn-info btn-lg"
                    data-toggle="modal"
                    data-target="#paymentModal"
                  >
                    Received Payment
                  </button>
                  <button
                    onClick={() => editHandler()}
                    type="button"
                    className="btn btn-info btn-lg"
                    data-toggle="modal"
                    data-target="#paymentModal"
                  >
                    Edit Invoice
                  </button>
                  <button
                    onClick={() => backToDashboard()}
                    type="button"
                    className="btn btn-info btn-lg"
                  >
                    Back to Dashboard
                  </button>
                </div>

                <div
                  id="smsModal"
                  className={`modal ${smsModal ? "show" : ""}`}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <span
                        onClick={() => setSmsModal(false)}
                        className="close"
                      >
                        &times;
                      </span>
                      <h2>Send SMS</h2>
                    </div>
                    <div className="modal-body">
                      {smsInfo && smsInfo.loading && (
                        <Loadingbox>Sending SMS, please wait...</Loadingbox>
                      )}
                      {smsInfo && smsInfo.error && (
                        <MessageBox variant="danger">
                          {smsInfo.error}
                        </MessageBox>
                      )}

                      <div className="bitly">
                        <p>
                          <b>Bitly Url : </b>
                        </p>
                        {pdfState === "loading" ? (
                          <Loadingbox>Loading, please wait...</Loadingbox>
                        ) : pdfState === "success" ? (
                          <></>
                        ) : pdfState === "error" ? (
                          <MessageBox variant="danger">
                            Error in Pdf Url
                          </MessageBox>
                        ) : (
                          ""
                        )}
                        <div className="tooltip">
                          <p>{bitlyUrl}</p>
                          <Link
                            onClick={() => copySMS()}
                            onMouseOut={() => outFunc()}
                          >
                            <span className="tooltiptext" id="myTooltip">
                              Copy to clipboard
                            </span>
                            Copy SMS
                          </Link>
                        </div>
                      </div>
                      {bitlyUrl !== "" ? (
                        <div className="message">
                          <p>
                            <b>Enter your message :</b>
                          </p>
                          <textarea
                            id="smsText"
                            value={smsTemplate}
                            onChange={(e) => setSmsTemplate(e.target.value)}
                            placeholder="Enter Message"
                          ></textarea>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="modal-footer">
                      {/* <h3>Modal Footer</h3> */}
                      <button onClick={() => sendSMS()}>Send SMS</button>
                    </div>
                  </div>
                </div>
                {/*Email Modal */}
                {/* <div class="popup">
                  <h1>
                    hey everyone !
                  </h1>
                  <span class="close">
                    <i class="fa fa-close"></i>
                  </span>
                </div> */}
                <div
                  id="emailModal"
                  className={`modal ${emailModal ? "show" : ""}`}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <span
                        onClick={() => setEmailModal(false)}
                        className="close"
                      >
                        &times;
                      </span>
                      <h2>Send Email</h2>
                    </div>
                    <div className="modal-body">
                      {emailInfo && emailInfo.loading && (
                        <Loadingbox>Sending Email, please wait...</Loadingbox>
                      )}
                      {emailInfo && emailInfo.error && (
                        <MessageBox variant="danger">
                          {emailInfo.error}
                        </MessageBox>
                      )}

                      <div className="message">
                        <p>
                          <b>Enter Message :</b>
                        </p>
                        <textarea
                          value={emailTemplate}
                          onChange={(e) => setEmailTemplate(e.target.value)}
                          placeholder="Enter Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button onClick={() => sendEmail()}>Send</button>
                    </div>
                  </div>
                </div>
                {/*Payment Modal */}
                <div
                  id="paymentModal"
                  className={`modal ${paymentModal ? "show" : ""}`}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <span
                        onClick={() => setPaymentModal(false)}
                        className="close"
                      >
                        &times;
                      </span>
                      <h4>Received Payment</h4>
                    </div>
                    <div className="modal-body">
                      {balanceFlag ? (
                        <div className="received-payment">
                          <form onSubmit={(e) => updatePayment(e)}>
                            <span className="rec-payment-input">
                              <label htmlFor="payment-option">
                                <b>Payment Method: </b>
                              </label>
                              <select
                                required
                                id="payment-option"
                                onChange={(e) => setPayMethod(e.target.value)}
                              >
                                <option value="" default>
                                  Please select payment option
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">
                                  Bank Transfer
                                </option>
                                <option value="Stripe">Stripe</option>
                                <option value="EFPOS">EFPOS</option>
                              </select>
                            </span>
                            <span className="rec-payment-input">
                              <label htmlFor="received-payment">
                                <b>Value: </b>
                              </label>
                              <input
                                required
                                max={balanceAmount}
                                min="0"
                                onChange={(e) =>
                                  setPayValue(parseFloat(e.target.value))
                                }
                                value={payValue}
                                id="received-payment"
                                step="any"
                                type="number"
                                placeholder="Enter received value"
                              />
                            </span>
                            {payValue === "" && payMethod === "" ? (
                              <></>
                            ) : (
                              <button type="submit">Payment Received</button>
                            )}
                          </form>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div>
                        {invoice.selectedInvoice.paymentList.length === 0 ? (
                          <></>
                        ) : (
                          <table
                            id="tblSearch"
                            className="table table-hover nowrap my-3"
                          >
                            <thead style={{ backgroundColor: "#37B6FF" }}>
                              <tr>
                                <th className="first" style={{ width: "45%" }}>
                                  Date
                                </th>
                                <th className="second" style={{ width: "25%" }}>
                                  Payment Method
                                </th>
                                <th className="second" style={{ width: "15%" }}>
                                  Payment Received
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {invoice.selectedInvoice.paymentList.map(
                                (v, i) => (
                                  <tr key={i}>
                                    <td
                                      className="first"
                                      style={{ width: "45%" }}
                                    >
                                      {v.payDate}
                                    </td>
                                    <td
                                      className="second"
                                      style={{ width: "25%" }}
                                    >
                                      {v.payMethod}
                                    </td>
                                    <td
                                      className="second"
                                      style={{ width: "15%" }}
                                    >
                                      ${parseFloat(v.payValue).toFixed(2)}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                      <div className="amount-details">
                        <div>
                          <p>
                            <b>Total Amount :</b>
                          </p>
                          <p>
                            $
                            {parseFloat(
                              invoice.selectedInvoice.totalAmount
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Paid Amount :</b>
                          </p>
                          <p>
                            $
                            {parseFloat(
                              invoice.selectedInvoice.paidAmount
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Balance Amount :</b>
                          </p>
                          <p>${parseFloat(balanceAmount).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer"></div>
                  </div>
                </div>
              </section>
              <div className="clearfix" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
export default GenerateInvoice;
