import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerCompany } from '../actions/companyActions';
import Header from '../components/Header'
import Loadingbox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

function NewCompany() {
    const companyRegister = useSelector((state) => state.companyRegister);
    const { loading, error } = companyRegister;
    
    const [formStep, setFormStep] = useState(1);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [phone, setPhone] = useState('');
    const [ABN, setABN] = useState('');
    
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    
    const [logoUrl, setLogoUrl] = useState('');
    const [initialNumber, setInitialNumber] = useState('');
    const [dropboxKey, setDropboxKey] = useState('');
    const [driveApi, setDriveApi] = useState('');
    const [stripePubKey, setStripePubKey] = useState('');
    const [stripeSecret, setStripeSecret] = useState('');

    const dispatch = useDispatch();

    const submitHandler = () => {
        if (formStep === 1) {
            setFormStep(2);
        } else if (formStep === 2) {
            setFormStep(3);
        } else if (formStep === 3) {
            dispatch(registerCompany(
                companyName,
                email,
                website,
                phone,
                ABN,
                userName,
                userEmail,
                userPassword,
                logoUrl,
                initialNumber,
                dropboxKey,
                driveApi,
                stripePubKey,
                stripeSecret
            ));
        }
    };
    return (
        <>
            <Header />         
            <div id="pages_maincontent">
                <h2 className="page_title" >
                    {formStep === 1 ? <></> :
                        <i onClick={() => setFormStep(formStep - 1)} className="fa fa-chevron-left back-ico"
                            aria-hidden="true"></i>}
                    <i className="fa fa-plus" aria-hidden="true" />                 
                        &nbsp;Register                   
                </h2>
                {formStep === 1 ?
                    <h3 id="dvResellerName" >
                        Enter Company Details
                </h3> :
                    <h3 id="dvResellerName" >
                        Enter User Details
                </h3>}
                <div className="page_single layout_fullwidth_padding">                   
                    <div className="loader">                       
                        {loading && <Loadingbox />}                        
                        {error && <MessageBox variant="danger">{error}</MessageBox>}                        
                    </div>                   
                    <div className="contactform" id="dvform">
                        {formStep === 1 ?
                            <>
                                <div className="form_row">
                                    <label>Company Name: </label>
                                    <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                        placeholder="Enter Company Name" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Email: </label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter Email" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Webiste: </label>
                                    <input type="email" value={website} onChange={(e) => setWebsite(e.target.value)}
                                        placeholder="Enter Website" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>Phone: </label>
                                    <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)}
                                        placeholder="Enter Phone" className="form_input required" />
                                </div>
                                <div className="form_row">
                                    <label>ABN: </label>
                                    <input type="number" value={ABN} onChange={(e) => setABN(e.target.value)}
                                        placeholder="Enter ABN" className="form_input required" />
                                </div>
                            </> :formStep === 2 ?
                            <>
                            <div className="form_row">
                                <label>User Name: </label>
                                <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
                                    placeholder="Enter User Name" className="form_input required" />
                            </div>
                            <div className="form_row">
                                <label>User Email: </label>
                                <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)}
                                    placeholder="Enter User Email" className="form_input required" />
                            </div>
                            <div className="form_row">                           
                                <label>User Password: </label>                      
                                <input type="password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)}
                                placeholder="Enter User Password" className="form_input required" />
                        </div>
                            </>:formStep === 3 ?
                            <>
                                <div className="form_row">
                                    <label>Image Link: </label>
                                    <input type="text" value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)}
                                        placeholder="Enter Image Url" className="form_input required" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Invoice Starting Number:</b></label>
                                    <input required value={initialNumber} onChange={(e)=>setInitialNumber(e.target.value)} min='10000' type="Number" placeholder="Enter initial number" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Dropbox App Key:</b></label>
                                    <input type="text" value={dropboxKey} onChange={(e) => setDropboxKey(e.target.value)}
                                        placeholder="DropBox App key" className="form_input required" /> 
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Google Drive API:</b></label>
                                    <input type="text" value={driveApi} onChange={(e) => setDriveApi(e.target.value)}
                                        placeholder="Google Drive API" className="form_input required" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Stripe Publishable Key:</b></label>
                                    <input type="text" value={stripePubKey} onChange={(e) => setStripePubKey(e.target.value)}
                                        placeholder="Stripe Puslishable Key" className="form_input required" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1"><b>Stripe Secret:</b></label>
                                    <input type="text" value={stripeSecret} onChange={(e) => setStripeSecret(e.target.value)}
                                        placeholder="Stripe Secret Key" className="form_input required" />
                                </div>
                            </>
                            :<></>}
                        <input onClick={() => submitHandler()} type="button" id="submit" name="submit"
                            className="form_submit" defaultValue={formStep===3?'Register':'Next'} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewCompany
