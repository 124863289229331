import Axios from "axios";
import { useSelector } from "react-redux";
import { serverAddress } from "../constants/dbConstants";
import {
  CLIENT_LIST_FAIL,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_SAVE_FAIL,
  CLIENT_SAVE_REQUEST,
  CLIENT_SAVE_SUCCESS,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  INVOICE_SAVE_FAIL,
  INVOICE_SAVE_REQUEST,
  INVOICE_SAVE_SUCCESS,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DELETE_FAIL,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_FAIL,
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_SAVE_FAIL,
  SERVICE_SAVE_REQUEST,
  SETTINGS_INFO_REQUEST,
  SETTINGS_INFO_SUCCESS,
  SETTINGS_SAVE_REQUEST,
  CHOOSE_FORM_TYPE,
  DATE_SAVE_SUCCESS,
  SERVICE_SAVE_SUCCESS,
  SERVICES_DELETE_REQUEST,
  SERVICES_DELETE_SUCCESS,
  SERVICES_DELETE_FAIL,
  FILE_SAVE_REQUEST,
  FILE_SAVE_SUCCESS,
  FILE_SAVE_FAIL,
  LIST_ITEMS_SAVE,
  REMOVE_INVOICE_INFO,
  REMOVE_FORM_TYPE,
  CUSTOMER_INVOICES_SUCCESS,
  CUSTOMER_INVOICES_FAIL,
  CUSTOMER_INVOICES_REQUEST,
} from "../constants/invoiceConstants";
import { sendEmailAdmin, sendEmailBackend } from "./emailActions";

// Form Type Actions
export const chooseFormType = (formType) => async (dispatch) => {
  dispatch({
    type: CHOOSE_FORM_TYPE,
    payload: formType,
  });
};

// Remove Form Info
export const removeFormInfo = () => async (dispatch) => {
  dispatch({
    type: REMOVE_FORM_TYPE,
  });
};

// Client Actions

// Get Existing Clients List
export const clientList = () => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: CLIENT_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(`${serverAddress}/api/clients`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CLIENT_LIST_SUCCESS,
      payload: data,
    });
    localStorage.setItem("clientsInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: CLIENT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Current Date
export const currentDate = (data) => async (dispatch) => {
  dispatch({
    type: DATE_SAVE_SUCCESS,
    payload: data,
  });
};

// Old Client
export const oldclient = (data) => async (dispatch) => {
  dispatch({
    type: CLIENT_SAVE_SUCCESS,
    payload: data,
  });
  localStorage.setItem("selectedClient", JSON.stringify(data));
};

// New Client
export const newclient =
  (clientName, companyName, email, address, phone, ABN) => async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: CLIENT_SAVE_REQUEST,
      payload: {
        clientName,
        companyName,
        email,
        address,
        phone,
        ABN,
      },
    });
    try {
      const { data } = await Axios.post(
        `${serverAddress}/api/clients/create`,
        {
          clientName,
          companyName,
          email,
          address,
          phone,
          ABN,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: CLIENT_SAVE_SUCCESS,
        payload: data.selectedClient[0],
      });
      localStorage.setItem(
        "selectedClient",
        JSON.stringify(data.selectedClient[0])
      );
    } catch (err) {
      dispatch({
        type: CLIENT_SAVE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

// Edit Customer
export const editCustomer =
  (_id, clientName, companyName, email, address, phone, ABN) =>
  async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: CLIENT_SAVE_REQUEST,
      payload: {
        _id,
        clientName,
        companyName,
        email,
        address,
        phone,
        ABN,
      },
    });
    try {
      const { data } = await Axios.post(
        `${serverAddress}/api/clients/update`,
        {
          _id,
          clientName,
          companyName,
          email,
          address,
          phone,
          ABN,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: CLIENT_SAVE_SUCCESS,
        payload: data.selectedClient[0],
      });
    } catch (err) {
      dispatch({
        type: CLIENT_SAVE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

//Delete Customer
export const deleteClient = (id) => async (dispatch) => {
  console.log("id action", id);
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: CLIENT_DELETE_REQUEST,
  });
  try {
    const { data } = await Axios.delete(
      `${serverAddress}/api/clients/delete/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: CLIENT_DELETE_SUCCESS,
      payload: data,
    });
    dispatch(clientList());
  } catch (err) {
    dispatch({
      type: CLIENT_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Services

// Get Existing Services List
export const serviceList = () => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: SERVICE_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(`${serverAddress}/api/services`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SERVICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SERVICE_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

//New Service
export const newservice =
  (shortDescription, longDescription, price) => async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: SERVICE_SAVE_REQUEST,
      payload: {
        shortDescription,
        longDescription,
        price,
      },
    });
    try {
      await Axios.post(
        `${serverAddress}/api/services/create`,
        {
          shortDescription,
          longDescription,
          price,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: SERVICE_LIST_REQUEST,
      });
      try {
        const { data } = await Axios.get(`${serverAddress}/api/services`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        dispatch({
          type: SERVICE_LIST_SUCCESS,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: SERVICE_LIST_FAIL,
          payload:
            err.response && err.response.data.message
              ? err.response.data.message
              : err.message,
        });
      }
    } catch (err) {
      dispatch({
        type: SERVICE_SAVE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

// Edit Service
export const editService =
  (_id, shortDescription, longDescription, price) => async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: SERVICE_SAVE_REQUEST,
      payload: {
        _id,
      },
    });
    try {
      const { data } = await Axios.put(
        `${serverAddress}/api/services/update/${_id}`,
        {
          _id,
          shortDescription,
          longDescription,
          price,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SERVICE_SAVE_SUCCESS,
        // payload: data.selectedClient[0],
      });
    } catch (err) {
      dispatch({
        type: SERVICE_SAVE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

//Delete Service
export const deleteService = (id) => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: SERVICES_DELETE_REQUEST,
  });
  try {
    const { data } = await Axios.delete(
      `${serverAddress}/api/services/delete/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: SERVICES_DELETE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SERVICES_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// New Invoice
export const newInvoice = (info) => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;
  dispatch({
    type: INVOICE_SAVE_REQUEST,
  });
  try {
    const { data } = await Axios.post(
      `${serverAddress}/api/invoices/new`,
      {
        type: info.type,
        quotation: info.quotation,
        invoice: info.invoice,
        receipt: info.receipt,
        clientName: info.clientName,
        clientId: info.clientId,
        companyName: info.companyName,
        email: info.email,
        phone: info.phone,
        address: info.address,
        ABN: info.ABN,
        listItems: info.listItems,
        gstType: info.gstType,
        gst: info.gst,
        subTotal: info.subTotal,
        totalAmount: info.totalAmount,
        paidAmount: info.paidAmount,
        balanceAmount: info.balanceAmount,
        invoiceDate: info.invoiceDate,
        quotationDate: info.quotationDate,
        receiptDate: info.receiptDate,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    localStorage.setItem("selectedInvoice", JSON.stringify(data));
    dispatch({
      type: INVOICE_SAVE_SUCCESS,
      payload: data,
    });
    const type =
      info.invoice === "Yes"
        ? "Invoice"
        : info.quotation === "Yes"
        ? "Quotation"
        : "Receipt";
    const subject = `${type} has been created Successfully`;
    // const emailTemplate = `<h2>${type} Description :</h2>\n<h3>\n${info.clientName} from ${info.companyName} just created the ${type} ${data.number} of total amount $${info.balanceAmount}</h3>`;
    const emailTemplate = `<h2>${type} Description :</h2>\n\n
            <h4>Company Name   : ${info.companyName}</h4>\n
            <h4>Customer Name  : ${info.clientName}</h4>\n
            <h4>${type} Number : ${data.number}</h4>\n
            <h4>${type} Amount : $${info.balanceAmount}</h4>\n
        `;

    dispatch(sendEmailAdmin("siddiqui@hotmail.co.uk", subject, emailTemplate));
    dispatch(invoiceList());
  } catch (err) {
    dispatch({
      type: INVOICE_SAVE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Get Invoices List
export const invoiceList = () => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: INVOICE_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(`${serverAddress}/api/invoices`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: INVOICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: INVOICE_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Select Invoice
export const selectInvoice = (invoice) => async (dispatch) => {
  dispatch({
    type: INVOICE_SAVE_REQUEST,
  });
  try {
    localStorage.setItem("selectedInvoice", JSON.stringify(invoice));
    console.log(
      "--------------------------------------------------------------------------------------------"
    );
    console.log(invoice);
    dispatch({
      type: INVOICE_SAVE_SUCCESS,
      payload: invoice,
    });
  } catch (err) {
    dispatch({
      type: INVOICE_SAVE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Selected Services List
export const selectedServicesList = (data) => (dispatch) => {
  dispatch({
    type: LIST_ITEMS_SAVE,
    payload: data,
  });
};
// Update Payment List
export const updatePaymentDetails =
  (_id, payMethod, payValue, payDate) => async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;

    dispatch({
      type: UPDATE_PAYMENT_REQUEST,
    });
    try {
      const { data } = await Axios.post(
        `${serverAddress}/api/invoices/updatepayment`,
        {
          _id,
          payMethod,
          payValue,
          payDate,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: INVOICE_SAVE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PAYMENT_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

// Edit Invoice
export const editInvoice = (token, updatedInvoice) => async (dispatch) => {
  console.log("token", token);
  dispatch({
    type: INVOICE_SAVE_REQUEST,
  });
  try {
    const { data } = await Axios.put(
      `${serverAddress}/api/invoices/update/${updatedInvoice._id}`,
      {
        listItems: updatedInvoice.listItems,
        totalAmount: updatedInvoice.totalAmount,
        paidAmount: updatedInvoice.paidAmount,
        balanceAmount: updatedInvoice.balanceAmount,
        gst: updatedInvoice.gst,
        subTotal: updatedInvoice.subTotal,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: INVOICE_SAVE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: INVOICE_SAVE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Delete Invoice
export const deleteInvoice = (id) => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;
  dispatch({
    type: INVOICE_DELETE_REQUEST,
  });
  try {
    const { data } = await Axios.delete(
      `${serverAddress}/api/invoices/delete/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: INVOICE_DELETE_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: INVOICE_DELETE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// Remove Invoice Info
export const removeInvoiceInfo = () => async (dispatch) => {
  dispatch({
    type: REMOVE_INVOICE_INFO,
  });
};

// Get Settings Info
export const getSettingsInfo = () => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;
  dispatch({
    type: SETTINGS_INFO_REQUEST,
  });
  try {
    const { data } = await Axios.get(`${serverAddress}/api/settings`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    localStorage.setItem("settingsInfo", JSON.stringify(data));
    dispatch({
      type: SETTINGS_INFO_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SERVICE_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

// // Update Settings
export const updateSettings =
  (
    initialNumber,
    stripeKey,
    dsClientID,
    dsClientSecret,
    themeColor,
    gstType,
    invoiceFooter,
    emailTemplate,
    smsTemplate
  ) =>
  async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: SETTINGS_SAVE_REQUEST,
    });
    try {
      const { data } = await Axios.post(
        `${serverAddress}/api/settings/update`,
        {
          initialNumber,
          stripeKey,
          dsClientID,
          dsClientSecret,
          themeColor,
          gstType,
          invoiceFooter,
          emailTemplate,
          smsTemplate,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: SETTINGS_INFO_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: SERVICE_LIST_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

// Save PDF in Backend
export const saveFilePDF =
  (fileUrl, invoice, companyName, invoiceId, companyFolder) =>
  async (dispatch) => {
    const user = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const token = user.token;
    dispatch({
      type: FILE_SAVE_REQUEST,
    });
    try {
      const { data } = await Axios.post(
        `${serverAddress}/api/assets/save`,
        {
          fileUrl,
          invoice,
          companyName,
          invoiceId,
          companyFolder,
        },
        {
          // mode: 'no-cors',
          headers: {
            authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      dispatch({
        type: FILE_SAVE_SUCCESS,
        payload: data.url,
      });
    } catch (err) {
      dispatch({
        type: FILE_SAVE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

// Get Customer Invoices List
export const customerInvoices = (id) => async (dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const token = user.token;

  dispatch({
    type: CUSTOMER_INVOICES_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      `${serverAddress}/api/clients/list/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("data received", data);
    dispatch({
      type: CUSTOMER_INVOICES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CUSTOMER_INVOICES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
