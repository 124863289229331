import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClient, editCustomer } from '../actions/invoiceActions';
import Header from '../components/Header';
import Loadingbox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { CLIENT_SAVE_RESET, INVOICE_SAVE_RESET } from '../constants/invoiceConstants';

function EditCustomersScreen(props) {

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, pinCodeLogin } = userSignin; 
    
    const selectedClient = useSelector((state) => state.choosenClientInfo);
    const { choosenClient } = selectedClient;

    const clientInfo = useSelector((state) => state.choosenClientInfo);
    const { loading, error, success } = clientInfo; 
    
    const [customerName, setCustomerName] = useState(choosenClient?choosenClient.clientName:'');
    const [companyName, setCompanyName] = useState(choosenClient?choosenClient.companyName:'');
    const [email, setEmail] = useState(choosenClient?choosenClient.email:'');
    const [address, setAddress] = useState(choosenClient?choosenClient.address:'');
    const [phone, setPhone] = useState(choosenClient?choosenClient.phone:'');
    const [ABN, setABN] = useState(choosenClient?choosenClient.ABN:'');

    const [messageFlag, setMessageFlag] = useState(false);

    const dispatch = useDispatch();
    
  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);
    
    useEffect(() => {
        dispatch({     
            type: CLIENT_SAVE_RESET,           
        });
    }, [])
    
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(editCustomer(choosenClient._id, customerName, companyName, email, address, phone, ABN));
        setTimeout(() => {
            dispatch({     
                type: CLIENT_SAVE_RESET,           
            });
        },2000)
    };

    const deleteHandler = () => {
        if (window.confirm("Are you sure, you want to delete this client ?")) {
            dispatch(deleteClient(choosenClient._id));
            props.history.goBack()
        }
    }

    return (
        <>
            <Header />
            <div id="pages_maincontent">     
                <h2 className="page_title">                   
                    <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico"
                        aria-hidden="true"></i>
                    <i className="fas fa-user-edit" aria-hidden="true" />       
                        &nbsp;Edit Client
                    </h2>
                <h3 id="dvResellerName" >
                    Edit Client details here
                </h3>
                <div className="loader">
                    {loading && <Loadingbox />}
                    {error && <MessageBox variant="danger">{error}</MessageBox>}
                    {success && <MessageBox variant='success'>ClientDetails Updated</MessageBox>}
                </div>
                <div className="page_single layout_fullwidth_padding">

                    <form onSubmit={(e)=>submitHandler(e)}>
            <div className="contactform" id="dvform">
            
                <div className="new-client">
                <div className="form_row">
                    <label required htmlFor="Name">Client Name: </label>
                    <input onChange={(e) => setCustomerName(e.target.value)} value={customerName}
                        type="text" id="Name" placeholder="Enter Name" className="form_input required" />
                    </div>
                <div className="form_row">
                    <label htmlFor="CompanyName">Client Company: </label>
                    <input onChange={(e) => setCompanyName(e.target.value)} value={companyName}
                        type="text" id="CompanyName" placeholder="Enter Client Company" className="form_input required" />
                </div>
                <div className="form_row">
                    <label required htmlFor="Email">Email: </label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email}
                        type="email" id="Email" placeholder="Enter Email" className="form_input required" />
                    </div>
                <div className="form_row">
                    <label htmlFor="Address">Address: </label>
                    <input onChange={(e) => setAddress(e.target.value)} value={address}
                        type="text" id="Address" placeholder="Enter Address" className="form_input required" />
                </div>
                <div className="form_row">
                    <label htmlFor="Phone">Phone: </label>
                    <input onChange={(e) => setPhone(e.target.value)} value={phone}
                        type="text" id="Phone" placeholder="Enter Phone" className="form_input required" />
                </div>
                <div className="form_row">
                    <label htmlFor="ABN">ABN: </label>
                    <input onChange={(e) => setABN(e.target.value)} value={ABN}
                        type="text" id="ABN" placeholder="Enter ABN" className="form_input required" />
                </div>
                        </div>
                        
                        <button  type="submit"
                               >Update Client</button>
                        
                        {/* <button onClick={()=>deleteHandler()}>Delete Client</button> */}
                        </div>
                        </form>
        </div>
        </div>
        </>
        )
    }

export default EditCustomersScreen;