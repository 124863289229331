import { DOCUSIGN_AUTH_CODE, DOCUSIGN_TOKENS,DOCUMENTS_LIST, ENVELOPES_LIST } from "../constants/docusignConstants";

export const docusignAuthReducer = (state = null, action) => {
    switch (action.type) {
        case DOCUSIGN_AUTH_CODE:
            return action.payload

        default:
            return state;
    }
};

export const docusignTokenReducer = (state = null, action) => {
    switch (action.type) {
        case DOCUSIGN_TOKENS:
            return action.payload

        default:
            return state;
    }
};
export const envelopesListReducer = (state = null, action) => {
    switch (action.type) {
        case ENVELOPES_LIST:
            return action.payload

        default:
            return state;
    }
};
export const documentsListReducer = (state = null, action) => {
    switch (action.type) {
        case DOCUMENTS_LIST:
            return action.payload

        default:
            return state;
    }
};

