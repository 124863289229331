import { USER_PININ_FAIL, USER_PININ_REQUEST, USER_PININ_SUCCESS, USER_PINOUT, USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNOUT } from "../constants/userConstants";

export const userSigninReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SIGNIN_REQUEST:
            return { loading: true };
        case USER_SIGNIN_SUCCESS:
            return {
                loading: false,
                userInfo: action.payload
            };
        case USER_SIGNIN_FAIL:
            return { ...state,
                loading: false,
                userInfo: {
                    error: action.payload
                } };
        
        case USER_PININ_REQUEST:
            return {
                ...state,
                pinCodeLogin: {
                    error: null
                },
                loading: true
            };
        case USER_PININ_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: {...state.userInfo, pinCodeSetup:true},
                pinCodeLogin: action.payload
            };
        case USER_PININ_FAIL:
            return {
                ...state,
                loading: false,
                pinCodeLogin: {
                    error: action.payload
                }
            };
        case USER_PINOUT:
            return {
                ...state,
                pinCodeLogin: {}
            };
        case USER_SIGNOUT:
            return state = {};
        default:
            return state;
    }
};
