import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceList, editInvoice, selectInvoice, deleteInvoice, invoiceList } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { INVOICE_SAVE_RESET } from "../constants/invoiceConstants";

function EditInvoicesScreen(props) {
    
  const invoiceId = props.match.params.id;
  
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  const token = userInfo.token;
  
  const servicesInfo = useSelector((state) => state.serviceInfo);

  const settingsInfo = useSelector((state) => state.settingsInfo);
  const { settings } = settingsInfo;

  const invoicesInfo = useSelector((state) => state.invoiceInfo);
  const { loading, error, success } = invoicesInfo;
  // const loading = true;
  
  const invoicesListInfo = useSelector((state) => state.invoicesListInfo);
  const { invoicesList } = invoicesListInfo;
  const selectedInvoice = invoicesList.filter(x => x._id === invoiceId)[0];
  
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [qty, setQty] = useState('');
  const [price, setPrice] = useState(0);
  const [serviceType, setServiceType] = useState('manualService');
  const [generateBtn, setGenerateBtn] = useState(false);
  
  const [serviceIndex, setServiceIndex] = useState();
  const [choosenService, setChoosenService] = useState(false);
  
  // console.log("service Index", serviceIndex)
  const [selectedService, setSelectedService] = useState({
    shortDescription: '',
    longDescription: '',
    price: '',
    itemNumber:0
  });
  let tempService = {};
  let lastItemIndex = selectedInvoice.listItems.length?selectedInvoice.listItems.length - 1:0;
  let lastItemNum = selectedInvoice.listItems[lastItemIndex] ?
    selectedInvoice.listItems[lastItemIndex].itemNumber : 0;
  const [itemNumber, setItemNumber] = useState(lastItemNum+1);

  const [listItems, setListItems] = useState(selectedInvoice.listItems);

  let subTotal = listItems.reduce((prev, next) => parseFloat(prev) + parseFloat(next.price*next.qty), 0);
  // console.log("total", subTotal)
  let gst = settings.gstType === 'no' ? 0 :
    settings.gstType === 'included' ? subTotal / 11 :
      settings.gstType === 'excluded' ? subTotal / 10 : 0;
  let totalAmount = settings.gstType === 'no' ? subTotal :
    settings.gstType === 'included' ? subTotal :
  settings.gstType === 'excluded' ? subTotal + gst:subTotal;
  let paidAmount = 0;
  let balanceAmount = parseFloat(totalAmount).toFixed(2) - parseFloat(paidAmount).toFixed(2);

  // console.log("subToatl", subTotal, typeof(subTotal))
  // console.log("gst", gst, typeof(gst))
  // console.log("total", totalAmount, typeof(totalAmount))
  const dispatch = useDispatch();

  
  
  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  useEffect(() => {
    dispatch({
      type: INVOICE_SAVE_RESET,
    });
  }, [])
  
  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  useEffect(() => {
    if (serviceType === 'manualService') {
      setSelectedService({
        longDescription: '',
        shortDescription: 'Manual Service',
        price: '',
        qty: ''
      });
      setQty('');
    } else if(serviceType==='existingService'){
      setChoosenService(false);
    }
  }, [serviceType]);

  useEffect(() => {
    if (serviceType === 'manualService') {

    } else if (serviceType === 'existingService') {
      setTimeout(() => {
        
        setSelectedService(servicesInfo.servicesList[serviceIndex]);
      },300)
    }
  }, [serviceIndex]);

  useEffect(() => {
    if(selectedService){
      if (serviceType === 'manualService') {
        setLongDescription(selectedService && selectedService.longDescription);
        setPrice(selectedService && selectedService.price);
        // setQty('');
      } else if (serviceType === 'existingService') {
        setLongDescription(selectedService.longDescription);
        setPrice(selectedService.price);
      }
    } else {
      setChoosenService(false);
    }
      // setQty(1);
      // setTimeout(() => {
      //   setSelectedService({
      //   ...selectedService,
      // })
      // },1000)
    
  }, [selectedService]);

  const longDescriptionHandler = (value) => {
    if (serviceType === 'manualService') {
      // console.log("val", value)
      setSelectedService({
        ...selectedService,
        longDescription: value
      });
      if (value === '') {
      setChoosenService(false);
      } else {
      setChoosenService(true);
      }
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        longDescription: value
      })
    }
  };

  useEffect(() => {
    if (serviceType === 'manualService') {
      // console.log("val", value)
      setSelectedService({
        ...selectedService,
        qty
      })
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        qty
      })
    }
  }, [qty]);

  const priceHandler = (value) => {
    if (serviceType === 'manualService') {
      // console.log("val", value)
      setSelectedService({
        ...selectedService,
        price: value
      })
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        price: value
      })
    }
  };

  // useEffect(() => {
  //   if (serviceType === 'manualService') {

  //   } else if(serviceType==='existingService'){
  //     setSelectedService(servicesInfo.servicesList && servicesInfo.servicesList[serviceIndex]);
  //   }
  // }, [serviceIndex]);

  // useEffect(() => {
  //   if (serviceType === 'manualService') {

  //   } else if(serviceType==='existingService'){
  //     setSelectedService(servicesInfo.servicesList && servicesInfo.servicesList[serviceIndex]);
  //   }
  // }, [serviceIndex]);


  console.log("Selected Service", selectedService)
  // useEffect(() => {
  //   if (serviceType === 'manualService') {
  //     setServiceIndex(0);
  //   }
  // }, [serviceType]);
  
  // useEffect(() => {
  //   if (servicesInfo.servicesList) {
  //     setSelectedService(servicesInfo.servicesList[serviceIndex]);
  //   }

  // }, [serviceIndex, servicesInfo]);
  
  // useEffect(() => {
  //   if (selectedService) {
  //     setLongDescription(selectedService.longDescription);
  //     setPrice(selectedService.price)
  //   }

  // }, [serviceIndex,selectedService])
  // useEffect(() => {
  //   tempService = selectedService;
  //   tempService.longDescription = longDescription;
  //   setSelectedService(tempService)
  // }, [longDescription]);

  // useEffect(() => {
  //   tempService = selectedService;
  //   if (typeof price === 'number'){
  //     tempService.price = price;
  //   } else {
  //     tempService.price = 0;
  //   }
  //   setSelectedService(tempService)
  // }, [price]);
  // console.log("temp==",selectedService)

  const addToList = () => {
    if (serviceType === 'existingService' && !choosenService) {
      alert('Please select service form the list');
    } else if (serviceType === 'existingService' && selectedService.longDescription === '') {
      alert('Please enter description');
    } else if (serviceType === 'manualService' && selectedService.longDescription === '') {
      alert('Please enter description');
      
    } else {
      setItemNumber(itemNumber + 1)
      // const tempService = servicesInfo.servicesList[serviceIndex];
      setListItems(prevArray => [...prevArray, {
        shortDescription: selectedService.shortDescription,
        longDescription: selectedService.longDescription,
        qty: qty,
        price: selectedService.price,
        itemNumber: itemNumber
      }]);
      if (serviceType === 'existingService') {

        setChoosenService(false);
        let select = document.getElementById('selectBox');
        select.selectedIndex = 0;
      } else {
        setSelectedService({
          longDescription: '',
          shortDescription: '',
          qty: '',
          price: ''
        });
        setQty('');
      }
    }
    // setShortDescription('Please select invoice service');
  }

  const removeFromList = (index) => {
      const removedItem = listItems.splice(index, 1);
      setListItems(listItems.filter(x => x.itemNumber !== removedItem[0].itemNumber));
  }

  const generateInvoice = () => {
    dispatch(selectInvoice(selectedInvoice));
    props.history.push('/generate');
  }

  const submitHandler = async () => {
    const date = GetFormattedDate();

    const updatedInvoice = {
      _id: selectedInvoice._id,
      listItems: listItems,
      subTotal,
      totalAmount,
      paidAmount,
      balanceAmount,
      gst
      };
    await dispatch(editInvoice(token, updatedInvoice));
    await dispatch(invoiceList());

    setTimeout(() => {
      dispatch({
        type: INVOICE_SAVE_RESET,
      }); 
    }, 2000);

    setGenerateBtn(true);

  }
    const GetFormattedDate=()=> {
    var todayTime = new Date();
    var month = todayTime.getMonth()+1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    return day + "/" + month + "/" + year;
  }

    const deleteHandler = () => {
        if (window.confirm("Are you sure, you want to delete this invoice ?")) {
            dispatch(deleteInvoice(selectedInvoice._id));
            props.history.goBack();
        }
    }
    return (
      <>
        <Header />     
        <div id="pages_maincontent">
          
          <h2 className="page_title" >           
            <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico"
              aria-hidden="true"></i>
            <i className="fa fa-plus" aria-hidden="true" />
              &nbsp;Edit Invoice
          </h2>
          
          <h3 id="dvResellerName" >
            Edit invoice details here
          </h3>
          {generateBtn ?
            <div className="generate-icon">
              <i className="fas fa-file-invoice" onClick={() => generateInvoice()}></i>
            </div> : <></>}
          <div className="loader">
            
          </div>
  
          <div className="page_single layout_fullwidth_padding">
            {selectedInvoice ?            
              <div className="client-details">

                <div>                  
                  <label>Name :</label>                 
                  <p>{selectedInvoice.clientName}</p>
                </div>
                
                <div>                 
                  <label>Email :</label>                 
                  <p>{selectedInvoice.email}</p>                  
                </div>
                
                <div>                 
                  <label>Phone :</label>                 
                  <p>{selectedInvoice.phone}</p>                  
                </div>
                
                <div>                
                  <label>ABN :</label>
                  <p>{selectedInvoice.ABN}</p>                  
                </div>
                
              </div> : <></>}
            
          
            {listItems.length !== 0 ?             
              <ul className="list">                
                {listItems.map((v, i) =>               
                  <li key={i}>                   
                    <div className="details">                      
                      {/* <div>{v.longDescription}</div>                       */}
                      <div>
                        {/* {v.longDescription} <b>( $ {parseFloat(v.price).toFixed(2)} x {v.qty})</b>                        */}
                        {v.longDescription} {Number(v.price) && v.price!==0?<b>( $ {parseFloat(v.price).toFixed(2)} x {v.qty})</b>:''}
                        
                        {/* {v.longDescription} {v.price !== 0 ? <b>( $ {parseFloat(v.price).toFixed(2)} x {v.qty})</b> : ''} */}

                      </div>
                    </div>
                    
                    <div className="actions">                     
                      <i onClick={() => removeFromList(i)}                        
                        className="fa fa-trash" aria-hidden="true" />                     
                    </div>
                    
                  </li>                  
                )}                
              </ul> : <></>}
            {loading && <Loadingbox />}
            {success &&
              <MessageBox variant='success'>Invoice Updated</MessageBox>}
            {error && <MessageBox variant="danger">{userInfo.error}</MessageBox>}
            
             <div className="contactform" id="dvform">             
             {/* <div className="form_row">                
                <label htmlFor="Select Client">Invoice Service: </label>                
                <select id="selectBox" onChange={(e) => {
                  setServiceIndex(e.target.value);
                  setQty(1);
                  setChoosenService(true)
                }}
                  className="form-select form-select-sm" aria-label=".form-select-sm example">                  
                  <option defaultValue>Please select invoice service</option>            
                  {servicesInfo.servicesList ? <>                    
                    {servicesInfo.servicesList.map((v, i) =>                                                 
                      <option key={i} id={i} value={i}>{v.shortDescription}</option>                     
                    )}                    
                  </>                    
                    : <></>}                  
                </select>                
              </div> */}
            
            <div className="form_row">
                <label htmlFor="Select Client">Service Type: </label>               
                {/* <select id="selectBox" onChange={(e) => {
                    setServiceType(e.target.value);
                    setChoosenService(false);
                }}
                  className="form-select form-select-sm" aria-label=".form-select-sm example">                 
                  <option defaultValue>Please select service type</option>                                 
                      <option value='existingService'>Service from list</option>
                      <option value='manualService'>Manual Service</option>              
                </select>                 */}
                <div className="radio-options">
                  <div className="radio">
                    <input defaultChecked type="radio" id="manualService" name="service" value="manualService"
                    onChange={(e)=>setServiceType('manualService')} />
                    <label htmlFor="manualService">Manual Service</label>
                  </div>
                  <div className="radio">
                    <input disabled={servicesInfo.servicesList && servicesInfo.servicesList.length < 1}
                      type="radio" id="existingService" name="service" value="existingService"
                    onChange={(e)=>setServiceType(e.target.value)} />
                    <label className={servicesInfo.servicesList && servicesInfo.servicesList.length < 1 ? 'disabled' : ''}
                      htmlFor="existingService">Service from list</label>
                  </div>
              </div>
            </div>
              {serviceType === 'existingService' ?
                <>
                  <div className="form_row">
                    <label htmlFor="Select Client">Service: </label>
                    <select id="selectBox" onChange={(e) => {
                      setServiceIndex(e.target.value);
                      setChoosenService(true);
                      setQty(1);
                    }}
                      className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option defaultValue>Please select service</option>
                      {servicesInfo.servicesList ? <>
                        {servicesInfo.servicesList.map((v, i) =>
                          <option key={i} id={i} value={i}>{v.shortDescription}</option>
                        )}
                      </>
                        : <></>}
                    </select>
                  </div>
                  {choosenService ?
                <>
                  <div className="form_row">
                    <label htmlFor="description">Description: </label>
                    <textarea onChange={(e) => longDescriptionHandler(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter description" />
                  </div>

                  <div className="form_row">
                    {choosenService ? <>
                      <i className="fas fa-plus-square" onClick={() => setQty(qty + 1)}></i>
                      {qty >= 1 ?
                        <i className="fas fa-minus-square" onClick={() => setQty(qty - 1)}></i> :
                        <i className="fas fa-minus-square"></i>}
                    </> : <></>}
                    <label htmlFor="amount">Quantity: </label>
                    <input onChange={(e) => setQty(e.target.value)} value={qty}
                      type="number" name="qty" id="qty" placeholder="Enter Quantity"
                      className="form_input required" />
                  </div>

                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input onChange={(e) => priceHandler(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                  </div>
                </> : <></>}
                  </>: serviceType === 'manualService' ?
                  <>
                  <div className="form_row">
                    <label htmlFor="description">Description: </label>
                    <textarea onChange={(e) => longDescriptionHandler(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter description" />
                  </div>

                  <div className="form_row">
                    <>
                      <i className="fas fa-plus-square" onClick={() => setQty(Number(qty) + 1)}></i>
                      {qty >= 2 ?
                        <i className="fas fa-minus-square" onClick={() => setQty(Number(qty) - 1)}></i> :
                        <i className="fas fa-minus-square"></i>}
                    </>
                    <label htmlFor="amount">Quantity: </label>
                    <input onChange={(e) => setQty(e.target.value)} value={qty}
                      type="number" name="qty" id="qty" placeholder="Enter Quantity"
                      className="form_input required" />
                  </div>
              
                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input onChange={(e) => priceHandler(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                  </div>
                </>:<></>}
              
            </div>
            

            <div className="btn-div">
              <button onClick={() => addToList()} disabled={!choosenService}>Add Item</button>           
              <button onClick={() => submitHandler()} >Save Invoice</button>
              <button onClick={() => deleteHandler()} >Delete Invoice</button>
            </div>
          </div>
        </div>
      </>
    );
  }
export default EditInvoicesScreen;