import { COMPANY_LIST_FAIL, COMPANY_LIST_REQUEST, COMPANY_LIST_SUCCESS } from "../constants/companyConstants";

export const accountingCompanyReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_LIST_REQUEST:
            return { loading: true };
        case COMPANY_LIST_SUCCESS:
            return {
                loading: false,
                companies: action.payload
            };
        case COMPANY_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};