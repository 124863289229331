import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import { clientList, deleteClient, oldclient } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

function CustomersScreen(props) {
  const [selectedClient, setSelectedClient] = useState({});
  const [editFlag, setEditFlag] = useState(false);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;

  const [dropDown, setDropDown] = useState('');
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clientList());

  }, [dispatch]);

  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  useEffect(() => {
    if (editFlag) {
      dispatch(oldclient(selectedClient));
      props.history.push('/customers/edit');
    }
    setEditFlag(false);
  }, [dispatch, props.history, selectedClient,editFlag]);

  const clientsInfo = useSelector((state) => state.clientInfo.clientsList);


  const editCustomer = (index) => {
    setSelectedClient(clientsInfo.clients[index]);

  }

  const deleteHandler = (id) => {
    console.log("client id==>",id)
    if (window.confirm("Are you sure, you want to delete this client ?")) {
      dispatch(deleteClient(id));
      // props.history.goBack()
      setDropDown('');
      }
    }

  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Clients
                </h2>
                <h3 id="dvResellerName" >
          Clients list
                </h3>
        
        <div className="page_single layout_fullwidth_padding">
          {clientsInfo ?
          <ul className="list">
              {clientsInfo.clients.map((v, i) =>
                <li key={i}>
                  <div className="details">
                    <div><b>{v.clientName} {v.companyName && ` ( ${v.companyName} )`}</b></div>
                    <div>
                      <span className="first">
                        {v.phone}
                      </span>
                      <span>
                        {v.email}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    <div class="dropdown">
                      {/* <button class="dropbtn">Right</button> */}
                    {/* <i onClick={() => { editCustomer(i); setEditFlag(true); }} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" /> */}
                      {dropDown === i ?
                        <i onClick={() => setDropDown('')} className="fas fa-times" aria-hidden="true" />
                        : <i onClick={() => setDropDown(i)} className="fas fa-ellipsis-h dropbtn" aria-hidden="true" />}
                      <div class="dropdown-content" style={dropDown===i?{display: 'block'}:{display:'none'}}>
                      <Link onClick={() => { editCustomer(i); setEditFlag(true); }}>Edit</Link>
                      <Link onClick={()=>deleteHandler(v._id)}>Delete</Link>
                        <Link to={`/customers/${v._id}`} >List</Link>
                      </div>
                    </div>
                  </div>
                </li>
              )}
                    
            </ul> : <Loadingbox />}
                </div>
      </div>
      </>
    );
  }
    export default CustomersScreen;