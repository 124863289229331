import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import LoginScreen from '../screens/LoginScreen';
import DashboardScreen from '../screens/DashboardScreen';
import CreateInvoiceScreen from '../screens/CreateInvoiceScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import ServicesScreen from '../screens/ServicesScreen';
import InvoiceDetailsScreen from '../screens/InvoiceDetailsScreen';
import ConfigurationScreen from '../screens/ConfigrationScreen';
import GenerateInvoice from '../screens/GenerateInvoice/GenerateInvoice';
import CustomersScreen from '../screens/CustomersScreen';
import EditCustomersScreen from '../screens/EditCustomersScreen';
import EditInvoicesScreen from '../screens/EditInvoicesScreen';
import NewCompany from '../screens/NewCompany';
import EditServiceScreen from '../screens/EditServiceScreen';
import CustomerInvoices from '../screens/CustomerInvoices';
import FilesScreen from '../screens/FilesScreen/FilesScreen';
import AccountsScreen from '../screens/AccountsScreen/AccountsScreen';
import DocuSignAuth from '../screens/DocuSignScreen/Auth';
import AuthData from '../screens/DocuSignScreen/AuthData';
import DocuSignDash from '../screens/DocuSignScreen/DocusignDash';
import DocumentsList from '../screens/DocuSignScreen/DocumentsList';
import SendDocument from '../screens/DocuSignScreen/SendDocument';
import EnvelopesList from '../screens/DocuSignScreen/EnvelopesList';
import AccountSetting from '../screens/AccountSetting';

export default class Routers extends Component {
    render() {
        return (
            <Router>
                <Route path='/' exact component={LoginScreen} />
                <Route path='/register' exact component={NewCompany} />
                <Route path='/dashboard' exact component={DashboardScreen} />
                <Route path='/create' exact component={CreateInvoiceScreen} />
                <Route path='/invoice-details' exact component={InvoiceDetailsScreen} />
                <Route path='/generate' exact component={GenerateInvoice} />
                <Route path='/services' exact component={ServicesScreen} />
                <Route path='/invoices' exact component={InvoicesScreen} />
                <Route path='/invoice/:id' component={EditInvoicesScreen} />
                <Route path='/services/edit/:id' component={EditServiceScreen} />
                <Route path='/customers' exact component={CustomersScreen} />
                <Route path='/customers/:id'  component={CustomerInvoices} />
                <Route path='/customers/edit' exact component={EditCustomersScreen} />
                <Route path='/configuration' exact component={ConfigurationScreen} />
                <Route path='/assets/:id' exact component={FilesScreen} />
                <Route path='/accounts' exact component={AccountsScreen} />
                <Route path='/docusign' exact component={DocuSignDash} />
                <Route path='/docusign/send' exact component={SendDocument} />
                <Route path='/docusign/envelopes' exact component={EnvelopesList} />
                <Route path='/docusign/envelopes/documents' component={DocumentsList} />
                <Route path='/setting' exact component={AccountSetting} />

            </Router>
       )
    }
};