import React from 'react';

function TemplateTwo() {
    return (
                <div  className="ali" style={{fontFamily:"sans-serif"}}    >
        <div className="pad" >
    
    
            <div  style={{display:"flex", alignItems: 'flex-end', justifyContent:"space-between", flexWrap:"wrap"  }} >
                
                
                <div className="MT"   >


 <div    >
                <img className="imagelogo" width="300px" height="100px"  src='https://onlinepayment.webnapp.com.au/img/supercityremovals.png' alt=""/>
</div>
        {/* <p style={{marginLeft:"50px", fontSize:"15px" , overflowWrap:"break-word"}} >Flat Rate Removals</p> */}
       
       
   <div>
    
        {/* <h3 className="MT" style={{marginTop:"50px",fontWeight:"bold"}}>Super City Removals</h3> */}
  </div>
                 </div>
    


<div className="   "    style={{marginTop:"20px"}} >
        <p>Karachi,SA,5047</p>
        <p>090078601</p>
    
        <p>Shaheershaikh@gmail.com</p>
    
        <p>www.shaheer.com</p>
    
        <p>ABN:109090909</p>
    
<div>
    
        <h5 className="MT" style={{marginTop:"20px",fontWeight:"bold"}}>Tax Invoice</h5>
</div>
    
    
</div>
    
        
        
        
</div>
        
        
        
        
</div>
    
    
    
    
    
    
    
    
    
    
    
<div  className="pad" style={{borderTop:"2px solid black",display:"flex", flexWrap:"wrap",justifyContent:"space-between" ,  padding:"0px 50px"}} >
    
    
   <div className="width"  style={{display:"flex", marginTop:"14px", overflowWrap:"break-word",  justifyContent:"space-between" }}>
        <div   >
<p style={{marginTop:"10px",fontWeight:"bold"}}>Bill To:</p>

        </div>
            <div   >
                    <p  style={{marginTop:"10px"}} >Idrees Interiors Pvt Ltd</p>
                    <p   style={{marginTop:"10px"}}>hellow@gmail.com</p>
    
                    <p  style={{marginTop:"10px"}}>178 children</p>
    
                    <p style={{marginTop:"10px"}}>North america</p>
    
                    
            </div>
    
    
    </div>
    
           
          
    
    
    <div  className="width" style={{display:"flex", marginTop:"14px" }}>
                <div  style={{width:"55%"}}  >
                    <p style={{marginTop:"10px",fontWeight:"bold"}}>Invoice No:</p>
                    <p style={{marginTop:"10px",fontWeight:"bold"}}> Date:</p>
    
                    <p style={{marginTop:"10px",fontWeight:"bold"}}> Due Date:</p>
    
    
    
                    
                </div>
    <div  style={{width:"45%"}}  >
                    <p style={{marginTop:"10px"}}>9077</p>
                    <p style={{marginTop:"10px"}}>12/01/2020</p>
    
                    <p style={{marginTop:"10px"}}>18/01/2020</p>
    
    
                    
                    
                    
                    </div>
    
    
            </div>
        
    
    </div>
    
    
    
    
    
    
    
    <div className="pad" style={{padding:"0px 50px",marginTop:"30px"}} >
    <table  style={{fontFamily:"sans-serif",width:"100%"}}>
  
  
  <tr style={{width:"20%",background:"lightblue", padding:"5px 10px"}} >
    <td   style={{fontWeight:"bold",width:"40%", padding: '5px', margin: '0px'}} >Description</td>
    <td style={{fontWeight:"bold",width:"20%", padding: '5px', margin: '0px'}}>Quantity</td>
    <td style={{fontWeight:"bold",width:"20%", padding: '5px', margin: '0px'}}>Rate</td>
    <td style={{fontWeight:"bold",width:"20%", padding: '5px', margin: '0px'}}>Amount</td>

  </tr>
  <tr style={{width:"20%", padding:"5px 10px"}}>
    <td  className="td">hellow this is description</td>
    <td className="td" >2</td>
    <td className="td" style={{padding:"5px 0px"}} >1000</td>
    <td  className="td"style={{padding:"5px 0px"}}>2000</td>

  </tr>

 

 
</table>
    
    </div>
    
    
    
    
    
    
    
    
    
    <div className="pad" style={{padding:"0px 50px" ,marginTop:"20px",display:"flex",justifyContent:"space-between",width:"100%"}}>
    <div className="" style={{width:"60%"}} >
    
    </div>
    <div className="" style={{display:"flex",justifyContent:"space-between",fontWeight:"bolder",width:"40%"}}>
        
        <div style={{width:"50%"}}>
            <p>Subtotal</p>
            <p>Gst 10 %($160)</p>
            <p>total</p>
            <p>Paid</p>
    
        </div>
    
        <div style={{width:"50%"}}>
            <p>$160</p>
            <p>$16</p>
    
            <p>$176</p>
    
            <p>$0</p>
    
        </div>
    </div>
    
    </div>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    <div className="pad" style={{padding:"0px 50px", marginTop:"20px", width:"100%",display:"flex",justifyContent:"space-between"}}>
    <div className="" style={{width:"50%"}}>
     </div>
    
    
    <div className="" style={{width:"50%"}} >
        
        <div  style={{background:"black",  padding:"10px", display:"flex",justifyContent:"space-between",  alignItems:"center", fontSize:"15px",color:"white"}}>
    <p>Balance due</p>
    <p>$176</p>
    
        </div>
       
    
       
    </div>
    
    </div>
    
    
    
    
    <div className="pad" style={{padding:"20px 50px"}}>
    <div className="" style={{fontWeight:"bold"}}>
    
    <p>Terms and Conditions</p>
    <p>Payment Terms:Cash/Internet Transfer</p>
    <p>Bank SA</p>
    <p>Acc name:Flatrate Removal</p>
    <p>BSB:105 096</p>
    <p>Account:069876640</p>
    <p>Terms:</p>
    <p>Prices are based on flat rate freight according to the details/information given at the time of booking by the client</p>
    
    
    
    
    </div>
    
    </div>
    
    
    
    
    </div>
    )
}

export default TemplateTwo
