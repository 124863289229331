
import { ADD_ENTRY, DELETE_ENTRY, FOLDER } from '../utils/constants';
import { DeleteEntry, AddEntry } from '../utils/fileSystem';
import { CHOOSE_DIRECTORY, CHOOSE_PARENT_DIRECTORY, CHOOSE_PATH, GET_ENTRIES_FAIL, GET_ENTRIES_REQUEST, GET_ENTRIES_SUCCESS, PUSH_TREE_ARRAY } from '../constants/fileConstants';

// export const fileSystemReducer= (data = dummyFileSystem, action) => {
//   switch (action.type) {
//     case ADD_ENTRY: {
//       const newEntry = action.payload;
//       return AddEntry(data, newEntry);
//     }

//     case DELETE_ENTRY: {
//       return DeleteEntry(data, action.payload);
//     }

//     default:
//       return data;
//   }
// };


//Files List
export const fileSystemReducer = (state = {}, action) => {
  console.log("files==>", action)
    switch (action.type) {
        case GET_ENTRIES_REQUEST:
            return {
                ...state,
                loading: true
            };
            
        case GET_ENTRIES_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload
            };
      
        case GET_ENTRIES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CHOOSE_PATH:
            return {
                ...state,
                currentPath: action.payload
            };
        case CHOOSE_DIRECTORY:
            return {
                ...state,
                currentDirectory: action.payload
            };
        case CHOOSE_PARENT_DIRECTORY:
            return {
                ...state,
                parentDirectory: action.payload
            };
        case PUSH_TREE_ARRAY:
            return {
                ...state,
                treeArray: action.payload
            };
        default:
            return state;
    }
};

//Current Path
export const currentPathReducer = (state = {}, action) => {
    switch (action.type) {
        case CHOOSE_PATH:
            return {
                ...state,
                path: action.payload
            };

        default:
            return state;
    }
};