import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { accountingCompanyReducer } from "./reducers/accountingCompanyReducers";
import { companyDetailsReducer, companyRegisterReducer } from "./reducers/companyReducers";
import { docusignAuthReducer, docusignTokenReducer, documentsListReducer, envelopesListReducer } from "./reducers/docusignReducers";
import { emailReducerInfo } from "./reducers/emailReducers";
import { fileSystemReducer } from "./reducers/fileSystemReducer";
import { clientListReducer, currentDateReducer, customerInvoiceListReducer, fileSaveReducer, formTypeReducer, invoiceListReducer, saveClient, selectedInvoiceReducer, serviceListReducer, settingsReducer } from "./reducers/invoiceReducers";
import { smsReducerInfo } from "./reducers/smsReducers";
import { userSigninReducer } from "./reducers/userReducer";


const initialState = {
    userSignin: {
        userInfo: localStorage.getItem('userInfo') ?
            JSON.parse(localStorage.getItem('userInfo'))
            : null,
        pinCodeLogin: localStorage.getItem('pinCodeLogin') ?
            JSON.parse(localStorage.getItem('pinCodeLogin'))
            :null,
    },
    invoiceInfo: {
        selectedInvoice: localStorage.getItem('selectedInvoice') ?
            JSON.parse(localStorage.getItem('selectedInvoice'))
            :null,
    },
    companyDetails: {
        companyDetails: localStorage.getItem('companyDetails') ?
            JSON.parse(localStorage.getItem('companyDetails'))
            :null,
    },
    choosenClientInfo: {
        choosenClient: localStorage.getItem('selectedClient') ?
            JSON.parse(localStorage.getItem('selectedClient'))
            :null, 
    },
    serviceInfo: {
        servicesLIst: [{}]
    },
    companyRegister:{},
    settingsInfo: {
        settings: localStorage.getItem('settingsInfo') ?
            JSON.parse(localStorage.getItem('settingsInfo'))
            :null,
    },
    fileSystem:
      localStorage.getItem('fileSystem') &&
      Object.keys(localStorage.getItem('fileSystem')).length > 0
        ? JSON.parse(localStorage.getItem('fileSystem'))
        : null

};
const reducer = combineReducers({
    userSignin: userSigninReducer,
    companyRegister: companyRegisterReducer,
    companyDetails: companyDetailsReducer,
    formInfo: formTypeReducer,
    currentDate: currentDateReducer,
    clientInfo: clientListReducer,
    choosenClientInfo: saveClient,
    serviceInfo: serviceListReducer,
    invoiceInfo: selectedInvoiceReducer,
    invoicesListInfo: invoiceListReducer,
    customerInvoicesListInfo: customerInvoiceListReducer,
    settingsInfo: settingsReducer,
    emailReducer: emailReducerInfo,
    smsReducer: smsReducerInfo,
    pdfLink: fileSaveReducer,
    fileSystem: fileSystemReducer,
    accessableCompanies: accountingCompanyReducer,
    docuSignAuth: docusignAuthReducer,
    docuSignTokens: docusignTokenReducer,
    documentsList: documentsListReducer,
    docuSignAuth: docusignAuthReducer,
    docuSignTokens: docusignTokenReducer,
    documentsList: documentsListReducer,
    envelopesList: envelopesListReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer,initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;