export const GET_ENTRIES_REQUEST = 'GET_ENTRIES_REQUEST';
export const GET_ENTRIES_SUCCESS = 'GET_ENTRIES_SUCCESS';
export const GET_ENTRIES_FAIL = 'GET_ENTRIES_FAIL';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const UPLOAD_FOLDER_REQUEST = 'UPLOAD_FOLDER_REQUEST';
export const UPLOAD_FOLDER_SUCCESS = 'UPLOAD_FOLDER_SUCCESS';
export const UPLOAD_FOLDER_FAIL = 'UPLOAD_FOLDER_FAIL';

export const CHOOSE_PATH = 'CHOOSE_PATH';
export const CHOOSE_DIRECTORY = 'CHOOSE_DIRECTORY';
export const CHOOSE_PARENT_DIRECTORY = 'CHOOSE_PARENT_DIRECTORY';
export const PUSH_TREE_ARRAY = 'PUSH_TREE_ARRAY'