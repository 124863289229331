import { DOCUSIGN_AUTH_CODE, DOCUSIGN_TOKENS, DOCUMENTS_LIST, ENVELOPES_LIST } from "../constants/docusignConstants";


// Auth Action
export const docusignAuth = (data) => async (dispatch) => {
    dispatch({
        type: DOCUSIGN_AUTH_CODE,
        payload: data
    });    
};

export const setDocuSignTokens = (data) => async (dispatch) => {
    dispatch({
        type: DOCUSIGN_TOKENS,
        payload: data
    });    
};
export const setEnvelopesList = (data) => async (dispatch) => {
    dispatch({
        type: ENVELOPES_LIST,
        payload: data
    });    
};
export const setDocumentsList = (data) => async (dispatch) => {
    dispatch({
        type: DOCUMENTS_LIST,
        payload: data
    });    
};