import { SMS_DATA_REMOVE, SMS_SEND_FAIL, SMS_SEND_REQUEST, SMS_SEND_SUCCESS } from "../constants/smsConstants";

export const smsReducerInfo = (state = {}, action) => {
    switch (action.type) {
        case SMS_SEND_REQUEST:
            return { loading: true };
        case SMS_SEND_SUCCESS:
            return {
                loading: false,
                smsData: action.payload
            };
        case SMS_SEND_FAIL:
            return { loading: false, error: action.payload };
        case SMS_DATA_REMOVE:
            return {};
        default:
            return state;
    }
};