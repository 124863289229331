import { useEffect } from "react";
import axios from 'axios'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import MessageBox from "../../components/MessageBox";
import { serverAddress } from "../../constants/dbConstants";
import { useHistory } from "react-router";
import { Multiselect } from 'multiselect-react-dropdown';
import Select from 'react-select';
import Loadingbox from "../../components/LoadingBox";


function SendDocument(props) {
 
  const history = useHistory();
  const docuSignAuth = useSelector(state => state.docuSignAuth);
  const docuSignTokens = useSelector(state => state.docuSignTokens);

  const [options, setOptions] = useState();


  useEffect(() => {
    if(!docuSignAuth){
        history.push('/')
    }
  }, []);

  useEffect(() => {
    if(docuSignTokens){
      if(!docuSignTokens.access_token){
          history.push('/docusign')
      }
    }else{
      history.push('/docusign')
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo'))
    : null;
    const token = user.token;
    axios.get(`${serverAddress}/api/docusign/files/all`,{
      headers: {
          'authorization': `Bearer ${token}`
      }
  })
      .then((result) => {
        // Do somthing
        console.log("data==>", result.data);
        setOptions(result.data)
      //   dispatch(setDocuSignTokens(result.data))
      })
      .catch((err) => {
        // Do somthing
        console.log("err==>", err)
      })
    }, []);


  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [email, setEmail] = useState();
  const text = `All parties have completed Sign Request: MA 11A Denmead Avenue, Campbelltown SA 5074.
  
  Please sign this form by following the 'Review Document' button above.
  
  Thank you for providing your email address to facilitate the signing of this agreement electronically. As discussed, you have agreed and consent to signing the agreement electronically. Please sign where indicated.`;
  const [emailBody, setEmailBody] = useState(text);
  const [subject, setSubject] = useState();
  const [name, setName] = useState();
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  let signTabObj = {
    documentId: "7",
    pageNumber: "1",
    tabLabel: "Sign Here",
    xPosition: "500",
    yPosition: "500"
  }
  const [signHere, setSignHere] = useState([]); 
  const [signHereTabs, setSignHereTabs] = useState([signTabObj]); 

const setPageNumber = (index, value)=>{
  let tempArr = signHereTabs;
  tempArr[index].pageNumber = value;
  setSignHereTabs(tempArr);
}
console.log("sign arr=>", signHereTabs);
const setXLocation = (index, value)=>{
  let tempArr = signHereTabs;
  tempArr[index].xPosition = value;
  setSignHereTabs(tempArr);
}

const setYLocation = (index, value)=>{
  let tempArr = signHereTabs;
  tempArr[index].yPosition = value;
  setSignHereTabs(tempArr);
}

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

const onSelect = (selectedList, selectedItem) => {
  
}

const onRemove = (selectedList, removedItem) => {
  
}

  const submitHandler = async(e)=>{
      e.preventDefault();
      setLoader(true);
      const dataObj = {
          // documents:[
          //     {
          //         documentBase64: file,
          //         documentId: "7",
          //         fileExtension: "PDF",
          //         name: fileName,
          //         tabs: {
          //           signHereTabs: [
          //             {
          //               documentId: "123",
          //               pageNumber: "1",
          //               tabLabel: "Sign Here :",
          //               xPosition: "72",
          //               yPosition: "720"
          //             }
          //           ]
          //         }
          //     }
          // ],
          documents: documents,
          emailBlurb: emailBody,
          emailSubject: subject,
          recipients: {
            signers: [
              {
                email: email,
                name: name,
                recipientId: "1",
                tabs: {
                  // fullNameTabs: [
                  //   {
                  //     documentId: "7",
                  //     pageNumber: "1",
                  //     tabLabel: "Enter full name",
                  //     xPosition: "10",
                  //     yPosition: "10"
                  //   }
                  // ],
                  // signHereTabs: [
                  //   {
                  //     documentId: "7",
                  //     pageNumber: "1",
                  //     tabLabel: "Sign Here",
                  //     xPosition: "500",
                  //     yPosition: "500"
                  //   },
                  //   {
                  //     documentId: "7",
                  //     pageNumber: "2",
                  //     tabLabel: "Sign Here",
                  //     xPosition: "50",
                  //     yPosition: "50"
                  //   }
                  // ]
                  // signHereTabs
                  signHereTabs:signHere
                }
              }
            ]
          },
          status: "sent"
      };
            
            axios.post(`${serverAddress}/api/docusign/send`, {
              dataObj,
              access_token: docuSignTokens.access_token
            })
              .then((result) => {
                // Do somthing
                console.log("data==>", result.data);
                
                setLoader(false);
                if(result.data.errorCode){
                  setError(result.data.message)
                }else{
                  setSuccess(true);
                  setTimeout(()=>{
                    setSuccess(false);
                  },2000);
                }
              //   dispatch(setDocuSignTokens(result.data))
              })
              .catch((err) => {
                // Do somthing
                console.log("err==>", err)
                setLoader(false);
                setError(err.message)
              })


  }
  const handleFileRead = async (event) => {
      const file = event.target.files[0]
      console.log("file===>", file)
      setFileName(file.name)
      const base64 = await convertBase64(file)
      console.log("split==>",base64.split(',')[1])
      setFile(base64.split(',')[1]);
    }

  const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
  }  
  
  
 const handleSelect = (options)=>{
  // console.log("selected option==>", options)
  let signHere = [];
  for(let i=0;i<options.length;i++){
    console.log("document ID ==>", options[i].documentId)
    signHere.push({
      documentId: options[i].documentId,
      pageNumber: "1",
      tabLabel: "Sign Here",
      xPosition: "500",
      yPosition: "700"
    })
  }
  setSignHere(signHere)
  setDocuments(options)
 }


 

  

  

  console.log("mail body==>", signHere)

  return (
    <>
      <Header />
    <div id="pages_maincontent">
      <h2 className="page_title">
        <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
        <i className="fa fa-plus" aria-hidden="true" />
          &nbsp;Create New
      </h2>
      <h3 id="dvResellerName" >
          Enter details here
      </h3>
          <div className="page_single layout_fullwidth_padding">
            
            {success && <MessageBox variant='success'>Document sent successfully</MessageBox>}
            {error!='' && <MessageBox variant='danger'>{error}</MessageBox>}

            <form onSubmit={(e) => submitHandler(e)}>
              <div className="contactform" id="dvform">
                {/* <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">Choose Document </h4>
                  <input type="file" id="date" onChange={e => handleFileRead(e)} />

                </div> */}

                <div className="form_row py-2">
                {/* <Multiselect
                  options={options} // Options to display in the dropdown
                  // selectedValues={selectedValue} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  /> */}
                    <h4 htmlFor="Select Client">Choose Documents </h4>
                        <Select
                          // value={selectedOption}
                          onChange={handleSelect}
                          options={options}
                          isMulti= {true}
                          placeholder='Select Documents'
                          className='react-select'
                        />
                </div>

                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">Email Subject: </h4>
                  <input type="text" id="date" style={{color:"black"}} onChange={e=>setSubject(e.target.value)} />

                </div>

                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">Email Body: </h4>
                  <textarea type="text" id="date" style={{color:"black"}} value={emailBody} onChange={e=>setEmailBody(e.target.value)} ></textarea>

                </div>

                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">Receipent Name </h4>
                  <input type="text" id="date" style={{color:"black"}}  onChange={e=>setName(e.target.value)} />

                </div>
                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">Receipent Email </h4>
                  <input type="email" id="date" style={{color:"black"}}  onChange={e=>setEmail(e.target.value)} />
                </div>

                {/* <h4>Sign Tabs</h4>
                <br /> */}
                {/* {signHereTabs.map((v,i)=>
                <div className="sign-box" key= {i}>
                <div className="form_row pt-2">
                    <h4 htmlFor="Select Client">Page Number :</h4>
                    <input type="number" style={{color:"black"}}  onChange={e=>setPageNumber(i,e.target.value)} />
                  </div>
                  <div className="form_row pt-2">
                    <h4 htmlFor="Select Client">X-Location :</h4>
                    <input type="number" style={{color:"black"}}  onChange={e=>setXLocation(i,e.target.value)} />
                  </div>
                  <div className="form_row pt-2">
                    <h4 htmlFor="Select Client">Y-Location </h4>
                    <input type="number" style={{color:"black"}}  onChange={e=>setYLocation(i,e.target.value)} />
                  </div>
                </div>)}
                  <button onClick={()=>setSignHereTabs(prev=>[...prev, signTabObj])}>Add More</button> */}

               
                {loader && <Loadingbox>Sending, please wait ...</Loadingbox>}
                <input type="submit" id="submit" name="submit" className="form_submit" value="Submit" />
              </div>
            </form>
          </div>
      </div>
    </>
    );
  }

  export default SendDocument;