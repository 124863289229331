import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { invoiceList, selectInvoice } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import { FILE_SAVE_RESET } from "../constants/invoiceConstants";

function InvoicesScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(invoiceList());
    // const clients = useSelector((state) => state.clients);

  }, [dispatch]);

  const invoicesListInfo = useSelector((state) => state.invoicesListInfo);
  const { loading, invoicesList } = invoicesListInfo;


  const getSelectedInvoice = useSelector((state) => state.invoiceInfo);

  
  const chooseInvoice = (index) => {
    setSelectedInvoice(invoicesListInfo.invoicesList[index]);
  }

  useEffect(() => {
    dispatch({
      type: FILE_SAVE_RESET,
    });
  }, []);

  useEffect(() => {
    dispatch(selectInvoice(selectedInvoice));

  }, [dispatch, selectedInvoice]);

  const [invoiceClick, setInvoiceClick] = useState(false);

  useEffect(() => {
    if (invoiceClick) {
      if (getSelectedInvoice.selectedInvoice) {
        props.history.push('/generate');
        setInvoiceClick(false);
      }
    }
  }, [dispatch, getSelectedInvoice, invoiceClick, props.history]);
  
    useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  const editInvoice = (_id) => {
    props.history.push(`/invoice/${_id}`);

  }


  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fa fa-bars" aria-hidden="true" />
                  &nbsp;Invoices
                </h2>
                <h3 id="dvResellerName" >
          Invoice list
                </h3>
        <div className="page_single layout_fullwidth_padding">
          {loading ? <Loadingbox /> :
            <>
              {invoicesList ?
                <ul className="list">
                  {invoicesList.map((v, i) =>
                    <li key={i}>
                      {v.deleted === 'No' ?
                        <>
                          {/* <div>
                    <i className="fa fa-file" aria-hidden="true" />
                  </div> */}
                          <div onClick={() => { chooseInvoice(i); setInvoiceClick(true) }} className="details">
                            {/* <div><b>{v.clientName}</b></div> */}
                            <div><b>{v.clientName} | {v.companyName} (${parseFloat(v.totalAmount).toFixed(2)})</b></div>
                            <div>
                              <span className="first">
                                {v.number}
                              </span>
                              {v.invoice === 'Yes' ?
                                <span>
                                  {v.invoiceDate}
                                </span> : <></>}
                              {v.quotation === 'Yes' ?
                                <span>
                                  {v.quotationDate}
                                </span> : <></>}
                              {v.receipt === 'Yes' ?
                                <span>
                                  {v.receiptDate}
                                </span> : <></>}
                            </div>
                            <div>
                              <span className="first">
                                {v.phone}
                              </span>
                              <span>
                                {v.email}
                              </span>
                            </div>
                          </div>
                          {v.stripePayment.isPaid ? <></> :
                            <div className="actions">
                              <i onClick={() => { editInvoice(v._id); }} className="fas fa-edit" aria-hidden="true" />
                            </div>}
                        </> : <></>}
                    </li>
                  )}
                </ul> : <></>}
            </>}
            </div>
        </div>
      </>
    );
  }
    export default InvoicesScreen;