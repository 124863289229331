import { useEffect } from "react";
import { useState } from "react";
import {Link, useHistory} from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { chooseFormType, clientList, currentDate, newclient, oldclient } from "../actions/invoiceActions";
import Header from "../components/Header";
import { updatePassword, updatePincode } from "../actions/userActions";
import MessageBox from "../components/MessageBox";

function AccountSetting(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  
  const clientsInfo = useSelector((state) => state.clientInfo.clientsList);
  
  const formTypeInfo = useSelector((state) => state.formInfo.formType);

  const [passwordFlag, setPasswordFlag ] = useState(false);
  const [pincodeFlag, setPincodeFlag ] = useState(false);
  const [cancelFlag, setCancelFlag ] = useState(true);

  const [password, setPassword ] = useState('');
  const [pincode, setPincode ] = useState('');

  const [passwordUpdateSuccess, setPasswordUpdateSuccess ] = useState(false);
  const [pincodeUpdateSuccess, setPincodeUpdateSuccess ] = useState(false);


  const history = useHistory();
  const dispatch = useDispatch();
  
  const submitHandler = (e) => {
    e.preventDefault();
    if(passwordFlag){
        dispatch(updatePassword(password));
        setPasswordUpdateSuccess(true);
        setTimeout(()=>{
            setPasswordUpdateSuccess(false);
            setCancelFlag(true);
            setPincodeFlag(false);
            setPasswordFlag(false);
        },1000);
    }else if(pincodeFlag){
        dispatch(updatePincode(pincode));
        setPincodeUpdateSuccess(true);
        setTimeout(()=>{
            setPincodeUpdateSuccess(false);
            setCancelFlag(true);
            setPincodeFlag(false);
            setPasswordFlag(false);
        },1000);
    }

  }

useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
}, [props.history, userInfo, pinCodeLogin]);

  return (
    <>
      <Header />
    <div id="pages_maincontent">
      <h2 className="page_title">
        <i onClick={()=>history.push('/dashboard')} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
        <i className="fa fa-cog" aria-hidden="true" />
          &nbsp;Account Setting
      </h2>
      <h3 id="dvResellerName" >
          Update details here
      </h3>
          <div className="page_single layout_fullwidth_padding">
          {passwordUpdateSuccess && <MessageBox variant="success">Password Updated</MessageBox>}
          {pincodeUpdateSuccess && <MessageBox variant="success">Pincode Updated</MessageBox>}

            <form onSubmit={(e) => submitHandler(e)}>
            {passwordFlag && 
              <div className="contactform" id="dvform">
                <div className="form_row pt-2">
                  <h4 htmlFor="password">Enter New Password: </h4>
                  <input type="text" id="password" placeholder="Enter new password" onChange={(e)=>setPassword(e.target.value)}
                      className="form_input required" />
                </div>

                <input type="button" onClick={()=>{setPasswordFlag(false); setPincodeFlag(false); setCancelFlag(true)}} className="form_submit" style={{marginRight: '5px'}} value="Cancel" />
                <input type="submit" id="submit" name="submit" className="form_submit" value="Update" />
              </div>}
            {pincodeFlag && 
              <div className="contactform" id="dvform">
                <div className="form_row pt-2">
                  <h4 htmlFor="pincode">Enter New Pincode: </h4>
                  <input type="text" id="pincode" placeholder="Enter new pincode" onChange={(e)=>setPincode(e.target.value)}
                      className="form_input required" />
                </div>

                <input type="button" onClick={()=>{setPasswordFlag(false); setPincodeFlag(false); setCancelFlag(true)}} className="form_submit" style={{marginRight: '5px'}} value="Cancel" />
                <input type="submit" id="submit" name="submit" className="form_submit" value="Update" />
              </div>}
            </form>
            {cancelFlag && <>
            <Link className="homepage_button btn btn-primary btn-lg btn-block" onClick={()=>{setPasswordFlag(true); setPincodeFlag(false); setCancelFlag(false)}}>
                Change Password</Link>
            <Link  className="homepage_button btn btn-primary btn-lg btn-block" onClick={()=>{setPasswordFlag(false); setPincodeFlag(true); setCancelFlag(false)}}>
                Change Pin Code</Link></>}
          </div>
      </div>
    </>
    );
  }

  export default AccountSetting;