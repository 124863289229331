import {
    CHOOSE_FORM_TYPE, CLIENT_LIST_FAIL, CLIENT_LIST_REQUEST,
    CLIENT_LIST_SUCCESS, CLIENT_SAVE_FAIL, CLIENT_SAVE_REQUEST,
    CLIENT_SAVE_RESET,
    CLIENT_SAVE_SUCCESS, INVOICE_LIST_FAIL, INVOICE_LIST_REQUEST,
    INVOICE_LIST_SUCCESS, INVOICE_SAVE_FAIL, INVOICE_SAVE_REQUEST,
    INVOICE_SAVE_RESET, INVOICE_SAVE_SUCCESS, SERVICE_LIST_FAIL, SERVICE_LIST_REQUEST,
    SERVICE_LIST_SUCCESS, SETTINGS_INFO_FAIL, SETTINGS_INFO_REQUEST,
    SETTINGS_INFO_SUCCESS, SETTINGS_SAVE_FAIL, SETTINGS_SAVE_REQUEST,
    SETTINGS_SAVE_SUCCESS, SETTINGS_SAVE_RESET, UPDATE_PAYMENT_SUCCESS, DATE_SAVE_SUCCESS, SERVICE_SAVE_REQUEST, SERVICE_SAVE_SUCCESS, SERVICE_SAVE_FAIL, SERVICE_SAVE_RESET, FILE_SAVE_REQUEST, FILE_SAVE_SUCCESS, FILE_SAVE_FAIL, LIST_ITEMS_SAVE, REMOVE_INVOICE_INFO, REMOVE_FORM_TYPE, CUSTOMER_INVOICES_REQUEST, CUSTOMER_INVOICES_SUCCESS, CUSTOMER_INVOICES_FAIL, FILE_SAVE_RESET
} from "../constants/invoiceConstants";

// Form Type Reducers
export const formTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case CHOOSE_FORM_TYPE:
            return {
                formType: action.payload,
            }
        case REMOVE_FORM_TYPE:
            return{}
        default:
            return state;
    }
};

// Form Type Reducers
export const currentDateReducer = (state = {}, action) => {
    switch (action.type) {
        case DATE_SAVE_SUCCESS:
            return {
                date: action.payload,
            }

        default:
            return state;
    }
};

// Client Reducers

//Client List
export const clientListReducer = (state = {}, action) => {
    switch (action.type) {
        case CLIENT_LIST_REQUEST:
            return { loading: true };
        case CLIENT_LIST_SUCCESS:
            return {
                loading: false,
                clientsList: action.payload
            };
        case CLIENT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

//New Client
export const saveClient = (state = {}, action) => {

    switch (action.type) {
        case CLIENT_SAVE_REQUEST:
            return { loading: true };
        case CLIENT_SAVE_SUCCESS:
            return {
                loading: false,
                success:true,
                choosenClient: action.payload
            };
        case CLIENT_SAVE_FAIL:
            return { loading: false, error: action.payload };
        case CLIENT_SAVE_RESET:
            return { 
                ...state,
                success: false
    };
        default:
            return state;
    }
};

//Service Reducers

export const serviceListReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SERVICE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                servicesList: action.payload.services
            };
        case SERVICE_LIST_FAIL:
            return {
                ...state,
                loading: false, error: action.payload
            };
        case SERVICE_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SERVICE_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                newService: action.payload
            };
        case SERVICE_SAVE_FAIL:
            return { 
                ...state,
                loading: false, error: action.payload
            };
                case SERVICE_SAVE_RESET:
            return { 
                ...state,
                success: false
            };
        default:
            return state;
    }
};

// Select Invoice Reducer
export const selectedInvoiceReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICE_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            };
        case INVOICE_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                selectedInvoice: action.payload
            };
        
        case INVOICE_SAVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case INVOICE_SAVE_RESET:
            return {
                ...state,
                success: false
            };
        case UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedInvoice: {
                    ...state.invoiceInfo.selectedInvoice,
                    paymentList: action.payload
                }
            }
        case LIST_ITEMS_SAVE:
            return {
                ...state,
                selectedServices: action.payload
            }
        case REMOVE_INVOICE_INFO:
            return{}
        default:

            return state;
    }
};

//Invoice List
export const invoiceListReducer = (state = {}, action) => {

    switch (action.type) {
        case INVOICE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
            
        case INVOICE_LIST_SUCCESS:
            return {
                loading: false,
                invoicesList: action.payload
            };
        case INVOICE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

//Settings Reducers

export const settingsReducer = (state = {}, action) => {
    switch (action.type) {
        case SETTINGS_INFO_REQUEST:
            return {
                loading: true,
                settings: {
                    stripeKey: '',
                    invoiceFooter: '',
                    emailTTemplate: '',
                    smsTemplate: ''
                }
            };
        case SETTINGS_INFO_SUCCESS:
            return {
                loading: false,
                success: true,
                settings: action.payload
            };
        case SETTINGS_INFO_FAIL:
            return { loading: false, error: action.payload };
        case SETTINGS_SAVE_REQUEST:
            return {
                loading: true,
                settings: {
                    stripeKey: '',
                    invoiceFooter: '',
                    emailTTemplate: '',
                    smsTemplate: ''
                }
            };
        
        case SETTINGS_SAVE_SUCCESS:
            return {
                loading: false,
                success: true,
                choosenClient: action.payload
            };
        case SETTINGS_SAVE_FAIL:
            return { loading: false, error: action.payload };
        case SETTINGS_SAVE_RESET:
            return { 
                ...state,
                success: false
            };
        default:
            return state;
    }
};
// Save Service
export const saveService = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case SERVICE_SAVE_SUCCESS:
            return {
                loading: false,
                success: true,
                newService: action.payload
            };
        case SERVICE_SAVE_FAIL:
            return { loading: false, error: action.payload };
                case SERVICE_SAVE_RESET:
            return { 
                ...state,
                success: false
            };
        default:
            return state;
    }
};


//PDF File Reducer
export const fileSaveReducer = (state = {}, action) => {
    switch (action.type) {
        case FILE_SAVE_REQUEST:
            return {
                loading: true
            };
        case FILE_SAVE_SUCCESS:
            return {
                loading: false,
                pdfUrl: action.payload
            };
        case FILE_SAVE_FAIL:
            return { loading: false, error: action.payload };
        case FILE_SAVE_RESET:
            return {  };
        default:
            return state;
    }
};

//Customer Invoices List
export const customerInvoiceListReducer = (state = {}, action) => {

    switch (action.type) {
        case CUSTOMER_INVOICES_REQUEST:
            return {
                ...state,
                loading: true
            };
            
        case CUSTOMER_INVOICES_SUCCESS:
            return {
                loading: false,
                invoicesList: action.payload
            };
        case CUSTOMER_INVOICES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};