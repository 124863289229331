import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import Header from "../../components/Header";
import LoadingBox from "../../components/LoadingBox";
import axios from 'axios'
import {setDocumentsList} from '../../actions/docusignActions.js'
import { serverAddress } from "../../constants/dbConstants";
import { useHistory } from "react-router";


function DocumentsList(props) {

  const history = useHistory();
  const docuSignAuth = useSelector(state => state.docuSignAuth);
  const docuSignTokens = useSelector(state => state.docuSignTokens);

  const documentsList= useSelector(state => state.documentsList);
  
  const [loader, setLoader] = useState('');

  const dispatch = useDispatch();


  useEffect(() => {
    if(!docuSignAuth){
        history.push('/')
    }
  }, []);

  useEffect(() => {
    if(docuSignTokens){
      if(!docuSignTokens.access_token){
          history.push('/docusign')
      }
    }else{
      history.push('/docusign')
    }
  }, []);

  useEffect(() => {
    async function fetch(){

          
          axios.post(`${serverAddress}/api/docusign/list`, {
            access_token: docuSignTokens.access_token
          }).then((result) => {
              // Do somthing
              console.log("data==>", result.data);
              dispatch(setDocumentsList(result.data))
            })
            .catch((err) => {
              // Do somthing
              console.log("err==>", err)
            })
    };
      fetch();
  
}, []);



const clickHandler = async(documentId, name)=>{
    const envelopeId = documentsList.envelopeId;
  setLoader(documentId);

  axios.post(`${serverAddress}/api/docusign/documents/download`, {
    access_token: docuSignTokens.access_token,
    envelopeId,
    documentId,
    name
  }).then((result) => {
      // Do somthing
      console.log("data==>", result.data);

      setLoader('');

      window.open(`${serverAddress}/api/docusign/assets/${result.data}`);
      // dispatch(setDocumentsList(result.data))
    })
    .catch((err) => {
      // Do somthing
      console.log("err==>", err)
      setLoader('')
    })

}


  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Documents
                </h2>
                <h3 id="dvResellerName" >
         Envelope Documents list
                </h3>
        
        <div className="page_single layout_fullwidth_padding">
        { documentsList &&  
                <ul className="list">


        { documentsList.envelopeDocuments.map((v,i)=>
            <>
                {v.type=='content' && 
                <li key={i} onClick={()=>clickHandler(v.documentId, v.name)}>
                  <div className="details">
                    <div><b className="mobilemarginview" style={{marginRight:"10px"}}>
                        {`${v.name}`} </b>
                         <b> {  `( Pages : ${v.pages && v.pages.length} )` }</b>  </div>
                    <div>
                      <span className="second">
                        Downloadable : {v.includeInDownload?'Yes':'No'}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    {loader===v.documentId &&
                    <LoadingBox onlyLoader={true}/>}
                  </div>
                </li>}
                </>
  )}

                    
            </ul> }
                </div>
      </div>
      </>
    );
  }
    export default DocumentsList