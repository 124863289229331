import Axios from "axios";
import { COMPANY_DETAILS_FAIL, COMPANY_DETAILS_REQUEST, COMPANY_DETAILS_SUCCESS, COMPANY_REGISTER_FAIL, COMPANY_REGISTER_REQUEST, COMPANY_REGISTER_SUCCESS } from "../constants/companyConstants";
import { serverAddress } from "../constants/dbConstants";

export const registerCompany = (
    companyName,
    email,
    website,
    phone,
    ABN,
    userName,
    userEmail,
    userPassword,
    logoUrl,
    initialNumber,
    dropboxKey,
    driveApi,
    stripePubKey,
    stripeSecret
) => async (dispatch) => {
    dispatch({
        type: COMPANY_REGISTER_REQUEST,
        payload: {
            companyName,
            website,
            email,
            phone,
            ABN,
            userName,
            userEmail,
            userPassword,
            logoUrl,
            initialNumber,
            dropboxKey,
            driveApi,
            stripePubKey,
            stripeSecret
        }
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/companies/new`, {
            companyName,
            website,
            email,
            phone,
            ABN,
            userName,
            userEmail,
            userPassword,
            logoUrl,
            initialNumber,
            dropboxKey,
            driveApi,
            stripePubKey,
            stripeSecret 
        });
        dispatch({
            type: COMPANY_REGISTER_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: COMPANY_REGISTER_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
    };

export const getCompanyInfo = (token, companyId) => async (dispatch) => {
    
    dispatch({
        type: COMPANY_DETAILS_REQUEST,
    });
    try {
        const { data } = await Axios.get(`${serverAddress}/api/companies`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });
        console.log("company data received ==>",data)
        localStorage.setItem('companyDetails', JSON.stringify(data));
        dispatch({
            type: COMPANY_DETAILS_SUCCESS,
            payload: data,
        });
     } catch (err) {
        dispatch({
            type: COMPANY_DETAILS_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

  