import { serverAddress } from '../constants/dbConstants';
import { ADD_ENTRY, DELETE_ENTRY } from '../utils/constants';
import Axios from "axios";
import { CHOOSE_PATH, GET_ENTRIES_FAIL, GET_ENTRIES_REQUEST, GET_ENTRIES_SUCCESS, CHOOSE_DIRECTORY, CHOOSE_PARENT_DIRECTORY, PUSH_TREE_ARRAY, UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL, UPLOAD_FOLDER_REQUEST, UPLOAD_FOLDER_SUCCESS, UPLOAD_FOLDER_FAIL } from '../constants/fileConstants';


export const getEntries = (folderName) => async (dispatch) => {
    const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
  const token = user.token;
  console.log("entries ==>",folderName)
    dispatch({
        type: GET_ENTRIES_REQUEST,
    });
    try {
        const { data } = await Axios.post(`${serverAddress}/api/assets`,{ folderName}, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
        dispatch({
            type: GET_ENTRIES_SUCCESS,
            payload: data,
        });
      // currentPath(data.path);
        localStorage.setItem('fileSystem', JSON.stringify(data));
      
     } catch (err) {
        dispatch({
            type: GET_ENTRIES_FAIL,
            payload: err.response && err.response.data.message ?
                err.response.data.message : err.message,
        });
    }
};

export const selectPath = (path) => async (dispatch) => {
    dispatch({
      type: CHOOSE_PATH,
      payload: path
    });
};

export const uploadFile = (file, path, currentPath) => async (dispatch) => {
  const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
  const token = user.token;
  console.log("token==>",token)
    dispatch({
        type: UPLOAD_FILE_REQUEST,
    });
  try {
    const { data } = await Axios.post(`${serverAddress}/api/assets/upload/${path}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'authorization': `Bearer ${token}`
      }
    });
    // dispatch(getEntries(currentPath));
    dispatch({
      type: UPLOAD_FILE_SUCCESS,
      payload: data,
    });
    // currentPath(data.path);
    // localStorage.setItem('fileSystem', JSON.stringify(data));
      
  } catch (err) {
    dispatch({
      type: UPLOAD_FILE_FAIL,
      payload: err.response && err.response.data.message ?
        err.response.data.message : err.message,
    });
  }
};

export const uploadFolder = (folder, path, currentPath) => async (dispatch) => {
  const user = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo'))
        : null;
  const token = user.token;
  console.log("token==>",token)
    dispatch({
        type: UPLOAD_FOLDER_REQUEST,
    });
  try {
    const { data } = await Axios.post(`${serverAddress}/api/assets/folderupload/${path}`, { folder }, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    });
    // dispatch(getEntries(currentPath));
    dispatch({
      type: UPLOAD_FOLDER_SUCCESS,
      payload: data,
    });      
  } catch (err) {
    dispatch({
      type: UPLOAD_FOLDER_FAIL,
      payload: err.response && err.response.data.message ?
        err.response.data.message : err.message,
    });
  }
};

export const selectDirectory = (dir) => async (dispatch) => {
    dispatch({
      type: CHOOSE_DIRECTORY,
      payload: dir
    });
    
};

export const selectParentDirectory = (dir) => async (dispatch) => {
    dispatch({
      type: CHOOSE_PARENT_DIRECTORY,
      payload: dir
    });
    
};

export const addTreeArray = (arr) => async (dispatch) => {
    dispatch({
      type: PUSH_TREE_ARRAY,
      payload: arr
    });
    
};

export const addEntry = entry => {
  return {
    type: ADD_ENTRY,
    payload: entry
  };
};

export const deleteEntry = entry => {
  return {
    type: DELETE_ENTRY,
    payload: entry
  };
};
