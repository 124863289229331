import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { customerInvoices, invoiceList, selectInvoice } from '../actions/invoiceActions';
import Header from '../components/Header';
import Loadingbox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

function CustomerInvoices(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  
  const [selectedInvoice, setSelectedInvoice] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

  useEffect(() => {
    dispatch(customerInvoices(props.match.params.id));
    // const clients = useSelector((state) => state.clients);

  }, [dispatch]);

  const customerInvoicesList = useSelector((state) => state.customerInvoicesListInfo);
  const { loading, invoicesList, error } = customerInvoicesList;


  const getSelectedInvoice = useSelector((state) => state.invoiceInfo);

  
  const chooseInvoice = (index) => {
    setSelectedInvoice(invoicesList[index]);
  }

  useEffect(() => {
    dispatch(selectInvoice(selectedInvoice));

  }, [dispatch, selectedInvoice]);
    
  useEffect(() => {
      if (props.match.params.id) { }else{
          history.push('/customers');
    }

  }, [props]);

  const [invoiceClick, setInvoiceClick] = useState(false);

  useEffect(() => {
    if (invoiceClick) {
      if (getSelectedInvoice.selectedInvoice) {
        props.history.push('/generate');
        setInvoiceClick(false);
      }
    }
  }, [dispatch, getSelectedInvoice, invoiceClick, props.history]);
  
    useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  const editInvoice = (_id) => {
    props.history.push(`/invoice/${_id}`);

  }


  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fa fa-bars" aria-hidden="true" />
                  &nbsp;Invoices
                </h2>
                <h3 id="dvResellerName" >
          Invoice list
                </h3>
        <div className="page_single layout_fullwidth_padding">
                  {loading ? <Loadingbox /> :
                      error ? <MessageBox variant='danger'>{error}</MessageBox>:
            <>
              {invoicesList ?
                <ul className="list">
                  {invoicesList.map((v, i) =>
                    <li key={i}>
                      {v.deleted === 'No' ?
                        <>
                          {/* <div>
                    <i className="fa fa-file" aria-hidden="true" />
                  </div> */}
                          <div onClick={() => { chooseInvoice(i); setInvoiceClick(true) }} className="details">
                            {/* <div><b>{v.clientName}</b></div> */}
                            <div><b>{v.clientName} | {v.companyName} (${parseFloat(v.totalAmount).toFixed(2)})</b></div>
                            <div>
                              <span className="first">
                                {v.number}
                              </span>
                              {v.invoice === 'Yes' ?
                                <span>
                                  {v.invoiceDate}
                                </span> : <></>}
                              {v.quotation === 'Yes' ?
                                <span>
                                  {v.quotationDate}
                                </span> : <></>}
                              {v.receipt === 'Yes' ?
                                <span>
                                  {v.receiptDate}
                                </span> : <></>}
                            </div>
                            <div>
                              <span className="first">
                                {v.phone}
                              </span>
                              <span>
                                {v.email}
                              </span>
                            </div>
                          </div>
                          {/* {v.stripePayment.isPaid ? <></> :
                            <div className="actions">
                              <i onClick={() => { editInvoice(v._id); }} className="fas fa-edit" aria-hidden="true" />
                            </div>
                            } */}
                        </> : <></>}
                    </li>
                  )}
                </ul> : <></>}
              </>}
          {invoicesList &&
            <div className="total-customer-data">
              <div>
                <div><h3>Total Invoices :</h3></div>
                <div><h3>{invoicesList.length}</h3></div>
              </div>
              <div>
                <div><h3>Total Cost :</h3></div>
              <div><h3>${invoicesList.reduce((prev, next) => prev + next.totalAmount, 0)}</h3></div>
              </div>
            </div>}
            </div>
        </div>
        </>
  )
}

export default CustomerInvoices
