import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import Navigation from '../../components/FileSystem/Navigation/index.js';
import SearchBar from '../../components/FileSystem/SearchBar/index.js';
import Grid from '../../components/FileSystem/Grid/index.js';
import List from '../../components/FileSystem/List.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectPath } from '../../actions/fileSystemActions.js';

export default function ViewFiles(props) {

  const fileSystem = useSelector(state => state.fileSystem);
  const { loading, error, list, currentPath, currentDirectory } = fileSystem;
  const [path, setPath] = useState(currentPath.split("assets")[1]);
  const dispatch = useDispatch();

  const goBack = () => {
    console.log("path==>", currentPath);
    // let splitPath = currentPath.split("assets")[1];
    const tempArr = currentPath.split('/');
    const a = tempArr.slice(0, -1).join('/');
    const b = tempArr.slice(-1).join('/');
    console.log("hello==>", a);
    
    dispatch(selectPath(a));
    // setPath(b.split("assets")[1]);

  } 

    return (
      // <Container>
      //   <TopBar>
          // <Navigation />
          // <SearchBar />
      //   </TopBar>
      //   <div className="data-box">
        // <Grid tree={this.props.tree} />
      //   </div>
      // </Container>
      <div className="files-view">
        <div className="head">
          <div className="navigation">
            {/* <Navigation /> */}
            <div className="icon">
              {currentPath !== list.path &&
                <i onClick={() => goBack()} class="fas fa-arrow-left"></i>}
            </div>
            <div className="path">
              <p>{currentPath.split("assets")[1]}</p>
            </div>
          </div>
          <div className="search">
            <SearchBar />
          </div>
        </div>
        <div className="body">
          <List tree={props.tree} />
        </div>
      </div>
    )
}
