import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectPath } from '../../actions/fileSystemActions';
import { serverAddress } from '../../constants/dbConstants';
import { FILE } from '../../utils/constants';

function List(props) {

  const { tree } = props;
  const fileSystem = useSelector(state => state.fileSystem);
   
  const dispatch = useDispatch();

  const clickHandler = async(entry) => {
    console.log("entry", entry)
    if (entry.type == FILE) {
      const pathArr = entry.path.split("/");
      let tempPath = `${pathArr[1]}`;
      console.log("pathArr==> ", pathArr);
      for (let i = 2; i < pathArr.length; i++) {
        tempPath = `${tempPath}&${pathArr[i]}`;
      }
      console.log("entry==? ",entry)
      const path = `${serverAddress}/api/assets/${tempPath}&dhk43895hvjdf8`
      console.log("tempPath==>", tempPath);
      // await Axios.get(path)
      window.open(path, '_blank');

    } else {
      dispatch(selectPath(entry.path));


    }
    }
    
    return (
        <ul className="list">
            {tree && tree.children.map((v, i) => 
            <li key={i} onClick={()=>clickHandler(v)}>
              <div className="image">
                {v.type == FILE ? <i class="fas fa-file-alt"></i> :
                    <i class="fas fa-folder"></i>}
              </div>
              <div className="name">
                <p>{v.name}</p>
              </div>
            </li>
            )}
          </ul>
    )
}

export default List
