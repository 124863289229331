import axios from "axios";
import { serverAddress } from "../constants/dbConstants";
import { SMS_DATA_REMOVE, SMS_SEND_FAIL, SMS_SEND_REQUEST, SMS_SEND_SUCCESS } from "../constants/smsConstants";

export const sendSMSAPI = (username, password, to, from, message) => async (dispatch) => {
    dispatch({
        type: SMS_SEND_REQUEST,
    });
    let formData = new FormData();
        formData.append('username', username)
        formData.append('password', password)
        formData.append('to', to)
        formData.append('from', from)
        formData.append('message', message)

        await axios({
            method: 'post',
            mode: 'no-cors',
            url: 'https://api.smsbroadcast.com.au/api-adv.php',
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(function (response) {
            //handle success
            console.log("responce==>", response)
            dispatch({
                type: SMS_SEND_SUCCESS,
                payload: response.data,
            });

        })
        .catch(function (response) {
            //handle error
            console.log("error==>", response)
            // dispatch({
            //     type: SMS_SEND_FAIL,
            //     payload: 'SMS not sent'
            // });
            dispatch({
                type: SMS_SEND_SUCCESS,
                payload: 'SMS Sent',
            });
        });
    }

export const removeSmsData= () => async (dispatch) => {
    dispatch({
        type: SMS_DATA_REMOVE,
    });
};