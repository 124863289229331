import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Container, Path } from './styles';
import GoBack from './GoBack';
import { useDispatch, useSelector } from 'react-redux';
import { selectPath } from '../../../actions/fileSystemActions';

const showPath = path => {
  const pathArr = path.split('/assets/').filter(p => p);
  const len = pathArr.length;
  const arr = [];

  pathArr.map((p, _) => {
    _ === len - 1
      ? arr.push(
          <span className="currentPath" key={_ + 1}>
            / {p}
          </span>
        )
      : arr.push(<span key={_ + 1}>{` / ${p} `}</span>);
  });
  return arr;
};

const goBack = path => {
  let newPath = path.split('/');
  newPath.splice(newPath.length - 1, 1);
  return newPath.join('/');
};

const Navigation = props => {

  const fileSystem = useSelector(state => state.fileSystem);
  const { loading, error, list, currentPath, currentDirectory } = fileSystem;
  const [path, setPath] = useState(currentPath.split("assets")[1]);
  const dispatch = useDispatch();

  const goBack = () => {
    console.log("path==>", currentPath);
    // let splitPath = currentPath.split("assets")[1];
    const tempArr = currentPath.split('/');
    const a = tempArr.slice(0, -1).join('/');
    const b = tempArr.slice(-1).join('/');
    console.log("hello==>", a);
    
    dispatch(selectPath(a));
    // setPath(b.split("assets")[1]);

  } 
  return (
    <Container>
      {currentPath !== list.path &&
        <div onClick={() => goBack()}
          style={{  marginTop:'-2', cursor: 'pointer' }}
        // onClick={() => {
        //   props.location.pathname === '/'
        //     ? null
        //     : props.history.push(goBack(props.location.pathname));
        // }}
        >
          <GoBack
            fill={'#545B61'}
          />
        </div>}
      <Path>{currentPath.split("assets")[1]}</Path>
    </Container>
  );
};

export default withRouter(Navigation);
