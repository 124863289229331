import React, { Component } from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import Axios from 'axios';
import SEO from '../SEO';

import { showPathEntries, entriesAreSame } from '../../../utils/fileSystem';
import { FILE, FOLDER } from '../../../utils/constants';
import { addEntry, deleteEntry, selectDirectory, selectPath } from '../../../actions/fileSystemActions';

import Icon from '../Icon';
import Add from '../Add';
import { validateYupSchema } from 'formik';
import { serverAddress } from '../../../constants/dbConstants';


const Grid = (props) => {
  const { tree } = props;
  const fileSystem = useSelector(state => state.fileSystem);
  const { parentDirectory } = fileSystem;
  

  // const fileSystem = useSelector(state => state.fileSystem);
  // const { loading, error, list } = fileSystem;
  // componentDidMount() {
  //   if (
  //     !Object.keys(this.props.fileSystem).includes(
  //       md5(this.props.location.pathname + FOLDER)
  //     )
  //   ) {
  //     this.props.history.push('/');
  //   }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.location.pathname === nextProps.location.pathname) {
  //     if (entriesAreSame(this.props.entry, nextProps.entry)) {
  //       return false;
  //     }
  //     return true;
  //   }
  //   return true;
  // }
  const dispatch = useDispatch();

  const clickHandler = async(entry) => {
    console.log("entry", entry)
    if (entry.type == FILE) {
      // const newPath = entry.path.replaceAll('\\','&');
      // const pathArr = newPath.split('assets&');
      // pathArr[0].repaceAll("\\", "&");
      // const newPath = pathArr[1].toString().replaceAll("\\", "xyz");
      // const path = `${serverAddress}/assets/${pathArr[1]}`;
      const pathArr = entry.path.split("/");
      let tempPath = `${pathArr[1]}`;
      console.log("pathArr==> ", pathArr);
      for (let i = 2; i < pathArr.length; i++) {
        tempPath = `${tempPath}&${pathArr[i]}`;
        // if(tempPath!=)
      }
      console.log("entry==? ",entry)
      const path = `${serverAddress}/api/assets/${tempPath}&dhk43895hvjdf8`
      console.log("tempPath==>", tempPath);
      // await Axios.get(path)
      window.open(path, '_blank');

    } else {
      dispatch(selectPath(entry.path));


    }
  }
    return (
      <Container>
        {/* <SEO
          url={props.match.url}
          title={props.match.url}
          image={'/assets/images/folder.png'}
          description={props.match.url}
        /> */}
        {tree && tree.children.map((v, i) => 
          <Icon
            handler={(entry)=>clickHandler(entry)}
            entry={v}
            index={i}
            key={`${v.path}_${v.type}`}
            deleteFn={() => {
              props.deleteEntry(md5(v.path + v.type));
            }}
          />
        )}
        {/* <Add
          saveEntry={value => {
            this.props.addEntry({
              ...value,
              parentID: md5(props.match.url + FOLDER),
              parentPath: props.match.url
            });
          }}
        /> */}
      </Container>
    );
}

export default Grid;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 40px 0;
`;
