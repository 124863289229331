export const CHOOSE_FORM_TYPE = 'CHOOSE_FORM_TYPE';
export const REMOVE_FORM_TYPE = 'REMOVE_FORM_TYPE';

export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAIL = 'CLIENT_LIST_FAIL';

export const DATE_SAVE_SUCCESS = 'DATE_SAVE_SUCCESS';

export const CLIENT_SAVE_REQUEST = 'CLIENT_SAVE_REQUEST';
export const CLIENT_SAVE_SUCCESS = 'CLIENT_SAVE_SUCCESS';
export const CLIENT_SAVE_FAIL = 'CLIENT_SAVE_FAIL';
export const CLIENT_SAVE_RESET = 'CLIENT_SAVE_RESET';

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';

export const SERVICE_LIST_REQUEST = 'SERVICE_LIST_REQUEST';
export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS';
export const SERVICE_LIST_FAIL = 'SERVICE_LIST_FAIL';

export const SERVICE_SAVE_REQUEST = 'SERVICE_SAVE_REQUEST';
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS';
export const SERVICE_SAVE_FAIL = 'SERVICE_SAVE_FAIL';
export const SERVICE_SAVE_RESET = 'SERVICE_SAVE_RESET';

export const SERVICES_DELETE_REQUEST = 'SERVICES_DELETE_REQUEST';
export const SERVICES_DELETE_SUCCESS = 'SERVICES_DELETE_SUCCESS';
export const SERVICES_DELETE_FAIL = 'SERVICES_DELETE_FAIL';

export const INVOICE_SAVE_REQUEST = 'INVOICE_SAVE_REQUEST';
export const INVOICE_SAVE_SUCCESS = 'INVOICE_SAVE_SUCCESS';
export const INVOICE_SAVE_FAIL = 'INVOICE_SAVE_FAIL';
export const INVOICE_SAVE_RESET = 'INVOICE_SAVE_RESET';

export const LIST_ITEMS_SAVE = 'LIST_ITEMS_SAVE';
export const REMOVE_INVOICE_INFO = 'REMOVE_INVOICE_INFO';

export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST';
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS';
export const INVOICE_LIST_FAIL = 'INVOICE_LIST_FAIL';

export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_DELETE_FAIL = 'INVOICE_DELETE_FAIL';

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAIL = 'UPDATE_PAYMENT_FAIL';

export const SETTINGS_INFO_REQUEST = 'SETTINGS_INFO_REQUEST';
export const SETTINGS_INFO_SUCCESS = 'SETTINGS_INFO_SUCCESS';
export const SETTINGS_INFO_FAIL = 'SETTINGS_INFO_FAIL';

export const SETTINGS_SAVE_REQUEST = 'SETTINGS_SAVE_REQUEST';
export const SETTINGS_SAVE_SUCCESS = 'SETTINGS_SAVE_SUCCESS';
export const SETTINGS_SAVE_FAIL = 'SETTINGS_SAVE_FAIL';
export const SETTINGS_SAVE_RESET = 'SETTINGS_SAVE_RESET';

export const FILE_SAVE_REQUEST = 'FILE_SAVE_REQUEST';
export const FILE_SAVE_SUCCESS = 'FILE_SAVE_SUCCESS';
export const FILE_SAVE_FAIL = 'FILE_SAVE_FAIL';
export const FILE_SAVE_RESET = 'FILE_SAVE_RESET';

export const CUSTOMER_INVOICES_REQUEST = 'CUSTOMER_INVOICES_REQUEST';
export const CUSTOMER_INVOICES_SUCCESS = 'CUSTOMER_INVOICES_SUCCESS';
export const CUSTOMER_INVOICES_FAIL = 'CUSTOMER_INVOICES_FAIL';