import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link, useHistory} from 'react-router-dom'
import { getCompanyInfo } from '../../actions/companyActions';
import { getEntries } from '../../actions/fileSystemActions';
import { getSettingsInfo, removeFormInfo, removeInvoiceInfo } from '../../actions/invoiceActions';
import { pinout, signout } from '../../actions/userActions';
import Header from '../../components/Header';
import OauthPopup from 'react-oauth-popup';
import { docusignAuth, setDocuSignTokens } from '../../actions/docusignActions';
import axios from 'axios';
import { serverAddress } from '../../constants/dbConstants';

function DashboardScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;    
  const dispatch = useDispatch();

  const signoutHandler = () => {
    dispatch(pinout());
    props.history.push('/')
  }
  const token = userInfo.token;
  const companyId = userInfo.companyId;

  const company = useSelector(state => state.companyDetails);
  const { companyDetails } = company;

  const history = useHistory();

  const docuSignAuth = useSelector(state => state.docuSignAuth);
  const docuSignTokens = useSelector(state => state.docuSignTokens);

  useEffect(() => {
    if(!docuSignAuth){
        history.push('/')
    }
  }, []);

  useEffect(() => {
    dispatch(getCompanyInfo(token, companyId));
    
  }, [dispatch, token, ]);

  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
  }, [props.history, userInfo, pinCodeLogin]);
  

useEffect(() => {
    async function fetch(){
        const headerCode= btoa(`${companyDetails.dsClientID}:${companyDetails.dsClientSecret}`);
        console.log("btoa==>", headerCode)

          axios.post(`${serverAddress}/api/docusign/token`, {
            headerCode,
            docuSignAuth
          })
            .then((result) => {
              // Do somthing
              console.log("tken data==>", result.data.refresh_token);
              dispatch(setDocuSignTokens(result.data))
              // dispatch(docusignAuth(result.data.refresh_token));
            })
            .catch((err) => {
              // Do somthing
              console.log("err==>", err)
            })
    };
    // if(docuSignAuth && companyDetails){
        fetch();
    // }
}, []);


  return (
    <>   
    <Header />   
    <div id="pages_maincontent" >
      <div className="log">
        <h2 className="page_title" >
          <i className="fa fa-home" aria-hidden="true" />
          &nbsp;Docusign Dashboard 
        </h2>
      <i onClick={()=>signoutHandler()} className="fa fa-sign-out" aria-hidden="true"></i>
      </div>
      {userInfo ?
      <h3 id="dvResellerName" >
          Welcome {userInfo.userName?userInfo.userName:'null'}
        </h3> :
          <></>}
        
        {companyDetails &&
          <div className="page_single layout_fullwidth_padding">
            <Link to="/docusign/send" className="homepage_button btn btn-primary btn-lg btn-block">
              Send Document
            </Link> 
            <Link to="/docusign/envelopes" className="homepage_button btn btn-primary btn-lg btn-block">
              Envelopes List
            </Link> 
          </div>} 
      </div>
    </>
    );
  }
export default DashboardScreen;