import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import Header from "../../components/Header";
import LoadingBox from "../../components/LoadingBox";
import axios from 'axios'
import {setDocumentsList, setEnvelopesList} from '../../actions/docusignActions.js'
import { serverAddress } from "../../constants/dbConstants";
import { useHistory } from "react-router";


function EnvelopesList(props) {

  const history = useHistory();
  const docuSignAuth = useSelector(state => state.docuSignAuth);
  const docuSignTokens = useSelector(state => state.docuSignTokens);

  const envelopesList= useSelector(state => state.envelopesList);
  
  const [loader, setLoader] = useState('');

  const dispatch = useDispatch();


  useEffect(() => {
    if(!docuSignAuth){
        history.push('/')
    }
  }, []);

  useEffect(() => {
    if(docuSignTokens){
      if(!docuSignTokens.access_token){
          history.push('/docusign')
      }
    }else{
      history.push('/docusign')
    }
  }, []);

  useEffect(() => {
    async function fetch(){

          
          axios.post(`${serverAddress}/api/docusign/envelopes`, {
            access_token: docuSignTokens.access_token
          }).then((result) => {
              // Do somthing
              console.log("data==>", result.data);
              dispatch(setEnvelopesList(result.data))
            })
            .catch((err) => {
              // Do somthing
              console.log("err==>", err)
            })
    };
      fetch();
  
}, []);



const clickHandler = async(envelopeId)=>{
  setLoader(envelopeId);

  axios.post(`${serverAddress}/api/docusign/documents`, {
    access_token: docuSignTokens.access_token,
    envelopeId
  }).then((result) => {
      // Do somthing
      console.log("data==>", result.data);

      setLoader('');
      dispatch(setDocumentsList(result.data))
      history.push('/docusign/envelopes/documents')
      // window.open(`${serverAddress}/api/docusign/assets/${result.data}`);
      // dispatch(setDocumentsList(result.data))
    })
    .catch((err) => {
      // Do somthing
      console.log("err==>", err)
      setLoader('')
    })

}


  return (
      <>
      <Header />
              <div id="pages_maincontent">
                <h2 className="page_title" >
                  <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
                  <i className="fas fa-users"></i>
                  &nbsp;Envelopes
                </h2>
                <h3 id="dvResellerName" >
          Envelopes list
                </h3>
        
        <div className="page_single layout_fullwidth_padding">
        { envelopesList &&  
                <ul className="list">


        { envelopesList.folderItems.map((v,i)=>

                <li key={i} onClick={()=>clickHandler(v.envelopeId)}>
                  <div className="details">
                    <div><b   className="mobilemarginview"  style={{marginRight:"10px"}}>{`${v.senderName}`} </b> <b> {  `( status : ${v.status})` }</b>  </div>
                    <div>
                      <span className="first">
                        {v.subject}
                      </span>
                    </div>
                  </div>
                  <div className="actions">
                    {loader===v.envelopeId &&
                    <LoadingBox onlyLoader={true}/>}
                  </div>
                </li>
  )}

                    
            </ul> }
                </div>
      </div>
      </>
    );
  }
    export default EnvelopesList