import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loadingbox from "../components/LoadingBox";
import { signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import Header from "../components/Header";
import PinCodeModal from "../components/Modal/PinCodeModal";

function LoginScreen(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [modalFlag, setModalFlag] = useState(false);

    const dispatch = useDispatch();
    const submitHandler = () => {
      dispatch(signin(email, password));
  };
  useEffect(() => {
    if (userInfo) {
      if (userInfo.loggedIn) {
        setDetailsSubmitted(true);
      }
      }
  }, [userInfo])
  useEffect(() => {
    if (detailsSubmitted && !loading) {
      setModalFlag(true);
    }
  }, [detailsSubmitted, loading])
    // useEffect(() => {
    //     if (userInfo) {
    //         props.history.push('/dashboard');
    //     }
    // }, [props.history, userInfo]);
  const modalStatus = (val) => {
    setModalFlag(false);
  }
  return (
    <>
      <Header />
        <div id="pages_maincontent">
          <h2 className="page_title" >
            <i className="fa fa-sign-in" aria-hidden="true" />
              &nbsp;Login Page
          </h2>
          <h3 id="dvResellerName" >
            Login here
          </h3>
        <div className="page_single layout_fullwidth_padding">
          <div className="loader">
            {loading && <Loadingbox/>}
                  {userInfo && userInfo.error && <MessageBox variant="danger">{userInfo.error}</MessageBox>}
          </div>
            <div className="contactform" id="dvform">
              <div className="form_row">
                <label>Email: </label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email" className="form_input required" />
              </div>
              <div className="form_row">
                <label>Password: </label>
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Your Password" className="form_input required" />
              </div>
              <input onClick={()=>submitHandler()} type="button" id="submit" name="submit" className="form_submit" defaultValue="Next" />
          </div>
          {/* <Link to="/register">Click here to Register as a new comapany</Link> */}
          {userInfo && userInfo._id &&
            <PinCodeModal modalStatus={modalFlag} closeModal={() => modalStatus()}></PinCodeModal>
          }</div>
        </div>
      </>
    );
  }
export default LoginScreen;