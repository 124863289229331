import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chooseFormType, clientList, currentDate, newclient, oldclient } from "../actions/invoiceActions";
import Header from "../components/Header";

function CreateInvoiceScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  
  const clientsInfo = useSelector((state) => state.clientInfo.clientsList);
  
  const formTypeInfo = useSelector((state) => state.formInfo.formType);
  
  const [clientStatus, setClientStatus] = useState(clientsInfo && clientsInfo.clients.length<1?'new':'old');
  const [clientIndex, setClientIndex] = useState(0);
  const [selectedClient, setSelectedClient] = useState({});
  const [clientName, setClientName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState();
  const [ABN, setABN] = useState();
  const [formType, setFormType] = useState(formTypeInfo?formTypeInfo:'');
  
  const [date, setDate] = useState(''); 
  
  let curr = new Date();
  curr.setDate(curr.getDate() + 3);
  let currDate = curr.toISOString().substr(0, 10);
  const [formattedDate, setFormattedDate] = useState(currDate);

  const [day, setDay] = useState(formattedDate.slice(8, 10));
  const [month, setMonth] = useState(formattedDate.slice(5, 7));
  const [year, setYear] = useState(formattedDate.slice(0, 4));

  const dateHandler = (value) => {
    setFormattedDate(value)
  }

  useEffect(() => {
    setDay(formattedDate.slice(8, 10));
    setMonth(formattedDate.slice(5, 7));
    setYear(formattedDate.slice(0, 4));
  }, [formattedDate]);

  useEffect(() => {
    setDate(`${day}/${month}/${year}`);
  }, [day, month, year]);
  

  console.log("day", month)
  
  console.log("date", date)
  
  const dispatch = useDispatch();
  
  const submitHandler = (e) => {
    e.preventDefault();

      dispatch(chooseFormType(formType));

      if (clientStatus === 'old') {    
        dispatch(oldclient(selectedClient));       
        dispatch(currentDate(date));       
        props.history.push('/invoice-details');       
      } else {       
        dispatch(newclient(clientName, companyName, email, address, phone, ABN));       
        dispatch(currentDate(date));       
        props.history.push('/invoice-details');
        
    }      

  }

  // useEffect(() => {
  //   if (clientsInfo ) {
  //     if (clientsInfo.clients.length < 1) {
  //       setClientStatus('new');
  //     }
  //   }
  // }, []);

  useEffect(() => {
    dispatch(clientList());

  }, [clientStatus, dispatch]);

  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);


  useEffect(() => {
    if (clientsInfo) {
      setSelectedClient(clientsInfo.clients[clientIndex]);
    }
  }, [clientIndex, clientsInfo]);
  return (
    <>
      <Header />
    <div id="pages_maincontent">
      <h2 className="page_title">
        <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>
        <i className="fa fa-plus" aria-hidden="true" />
          &nbsp;Create New
      </h2>
      <h3 id="dvResellerName" >
          Enter details here
      </h3>
          <div className="page_single layout_fullwidth_padding">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="contactform" id="dvform">
                <div className="form_row pt-2">
                  <h4 htmlFor="Select Client">What do you want to create: </h4>
                  <select required value={formType} onChange={(e) => setFormType(e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value='' default>Please select option</option>
                    <option value='Invoice'>Invoice</option>
                    <option value='Quotation'>Quotation</option>
                    <option value='Receipt'>Receipt</option>
                  </select>
                </div>

                <div className="form_row pt-2">
                  <h4 htmlFor="date">Select Date: </h4>
                  <input type="date" id="date" defaultValue={formattedDate} onChange={(e) => dateHandler(e.target.value)} />
                </div>

                <div className="form_row">
                  <div className="radio-options">
                    <div className="radio">
                      <input disabled={clientsInfo && clientsInfo.clients.length < 1} defaultChecked type="radio" id="old" name="client" value="old"
                        onChange={(e) => setClientStatus(e.target.value)} />
                      <label className={clientsInfo && clientsInfo.clients.length < 1 ? 'disabled' : ''}
                        htmlFor="old">Old Client</label>
                    </div>
                    <div className="radio">
                      <input defaultChecked={clientsInfo && clientsInfo.clients.length < 1} type="radio" id="new" name="client" value="new"
                        onChange={(e) => setClientStatus(e.target.value)} />
                      <label htmlFor="new">New Client</label>
                    </div>
                  </div>
                </div>
                {clientStatus === 'old' ?
                  <div className="old-client">
                    <div className="form_row">
                      <label htmlFor="Select Client">Select Client: </label>
                      <select required={true} onChange={(e) => setClientIndex(e.target.value)} className="form-select form-select-sm" aria-label=".form-select-sm example">
                        <option value='' default >Please select a client</option>
                        {clientsInfo ? <>
                          {clientsInfo.clients.map((v, i) =>
                            <option key={i} id={i} value={i}>{v.clientName}</option>
                          )}
                        </>
                          : <></>}
                      </select>
                    </div>
                  </div> : clientStatus === 'new' ?
                    <div className="new-client">
                      <div className="form_row">
                        <label htmlFor="Name">Client Name: </label>
                        <input required onChange={(e) => setClientName(e.target.value)} value={clientName}
                          type="text" id="Name" placeholder="Enter Name" className="form_input required" />
                      </div>
                      <div className="form_row">
                        <label htmlFor="CompanyName">Client Company: </label>
                        <input onChange={(e) => setCompanyName(e.target.value)} value={companyName}
                          type="text" id="CompanyName" placeholder="Enter Client Company" className="form_input required" />
                      </div>
                      <div className="form_row">
                        <label htmlFor="Email">Email: </label>
                        <input required onChange={(e) => setEmail(e.target.value)} value={email}
                          type="email" id="Email" placeholder="Enter Email" className="form_input required" />
                      </div>
                      <div className="form_row">
                        <label htmlFor="Address">Address: </label>
                        <input onChange={(e) => setAddress(e.target.value)} value={address}
                          type="text" id="Address" placeholder="Enter Address" className="form_input required" />
                      </div>
                      <div className="form_row">
                        <label htmlFor="Phone">Phone: </label>
                        <input onChange={(e) => setPhone(e.target.value)} value={phone}
                          type="number" id="Phone" placeholder="Enter Phone" className="form_input required" />
                      </div>
                      <div className="form_row">
                        <label htmlFor="ABN">ABN: </label>
                        <input onChange={(e) => setABN(e.target.value)} value={ABN}
                          type="number" id="ABN" placeholder="Enter ABN" className="form_input required" />
                      </div>
                    </div>
                    : <></>}
                <input type="submit" id="submit" name="submit" className="form_submit" value="Next" />
              </div>
            </form>
          </div>
      </div>
    </>
    );
  }

  export default CreateInvoiceScreen;