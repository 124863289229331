import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { deleteService, editService } from '../actions/invoiceActions';
import Header from '../components/Header';
import Loadingbox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { SERVICE_SAVE_RESET } from '../constants/invoiceConstants';

function EditServiceScreen(props) {

    const serviceInfo = useSelector((state) => state.serviceInfo);
    const { servicesList, loading, success, error } = serviceInfo;
    
    let service = servicesList.filter(x => x._id === props.match.params.id)[0];

    const [shortDescription, setShortDescription] = useState(service.shortDescription?service.shortDescription:'');
    const [longDescription, setLongDescription] = useState(service.longDescription?service.longDescription:'');
    const [price, setPrice] = useState(service.price?service.price:'');
    
    const dispatch = useDispatch();

    const deleteHandler = () => {
        if (window.confirm("Are you sure, you want to delete this service ?")) {
            dispatch(deleteService(service._id));
            props.history.goBack();
        } 
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(editService(service._id, shortDescription, longDescription, price));
        setTimeout(() => {
            dispatch({     
                type: SERVICE_SAVE_RESET,           
            });
        },2000)  
    };

    return (
                <>
            <Header />
            <div id="pages_maincontent">     
                <h2 className="page_title">                   
                    <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico"
                        aria-hidden="true"></i>
                    <i className="fas fa-user-edit" aria-hidden="true" />       
                        &nbsp;Edit Customers
                    </h2>
                <h3 id="dvResellerName" >
                    Edit Customer details here
                </h3>
                <div className="loader">
                    {loading && <Loadingbox />}
                    {error && <MessageBox variant="danger">{error}</MessageBox>}
                    {success && <MessageBox variant='success'>Service Updated</MessageBox>}
                </div>
                <div className="page_single layout_fullwidth_padding">

                    <div className="contactform" id="dvform">

                        <div className="form_row">
                            <label htmlFor="shortdescription">Short Description: </label>                
                            <textarea onChange={(e) => setShortDescription(e.target.value)}                              
                                value={shortDescription}                               
                                name="shortdescription" id="shortdescription" rows={10}                               
                                placeholder="Enter short description" />                       
                        </div>
                        
                <div className="form_row">
                    <label htmlFor="description">Long Description: </label>
                    <textarea onChange={(e) => setLongDescription(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter long description" />
                  </div>
              
                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input  onChange={(e) => setPrice(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                        </div>

                        <button onClick={(e) => submitHandler(e)} type="button"
                        >Update Service</button>
                        <button onClick={()=>deleteHandler()}>Delete Service</button>
                    </div>
                </div>
            </div>
            </>
    )
}

export default EditServiceScreen
