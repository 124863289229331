import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSettings } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { SETTINGS_SAVE_RESET } from "../constants/invoiceConstants";

function ConfigurationScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin;
  
  const settingsInfo = useSelector((state) => state.settingsInfo);
  const { settings, loading, error, success } = settingsInfo;
  
  const [stripeKey, setStripeKey] = useState(settings.stripeKey ? settings.stripeKey : '');
  const [dsClientID, setDsClientID] = useState(settings.dsClientID ? settings.dsClientID : '');
  const [dsClientSecret, setDsClientSecret] = useState(settings.dsClientSecret ? settings.dsClientSecret : '');
  const [themeColor, setThemeColor] = useState(settings.themeColor ? settings.themeColor : '#FFFFFF');
  const [gstType, setGstType] = useState(settings.gstType ? settings.gstType : '');
  const [initialNumber, setInitialNumber] = useState(settings.initialNumber?settings.initialNumber:'');
  const [invoiceFooter, setInvoiceFooter] = useState(settings.invoiceFooter?settings.invoiceFooter:'');
  const [emailTemplate, setEmailTemplate] = useState(settings.emailTemplate?settings.emailTemplate:'');
  const [smsTemplate, setSmsTemplate] = useState(settings.smsTemplate?settings.smsTemplate:'');
  const [messageFlag, setMessageFlag] = useState(false);
  console.log("gst",gstType)
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({     
      type: SETTINGS_SAVE_RESET,           
    });
  }, [])
  
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateSettings(initialNumber, stripeKey, dsClientID, dsClientSecret, themeColor, gstType, invoiceFooter, emailTemplate, smsTemplate));
    setTimeout(() => {
      dispatch({     
        type: SETTINGS_SAVE_RESET,           
      });
    },2000)
  }
    useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);
  
  // console.log("theme color", themeColor)
    return (
      <>
        <Header />
        {settingsInfo.settings ?

          <div id="pages_maincontent">
            <h2 className="page_title page1" >
              <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico" aria-hidden="true"></i>

              <i className="fa fa-cog" aria-hidden="true"></i>
                  &nbsp; Configuration
                </h2>
            <h3 id="dvResellerName" >
              Make changes here
            </h3>
            <div className="loader">
              {loading && <Loadingbox />}
              {error && <MessageBox variant="danger">{error}</MessageBox>}
              {success && <MessageBox variant='success'>Customer Details Updated</MessageBox>}
            </div>

        <div className="page_single layout_fullwidth_padding">
            <div className="container-fluid1 container-fluid">
              <div className="row">
                <div className="col-md-12 main123 ">
                  <form onSubmit={(e) => submitHandler(e)}>
                    {/* <div className="form-group">
                      <label htmlFor="exampleInputEmail1"><b>Starting Number:</b></label>
                      <input disabled={settings.initialNumber ? true : false} required value={initialNumber} onChange={(e)=>setInitialNumber(e.target.value)} min='10000' type="Number" placeholder="Enter initial number" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1"><b>GST Type:</b></label>
                      {/* <select onChange={(e) => setGST(e.target.value)} placeholder="Select GST">
                        <option>No</option>
                        <option>Included</option>
                        <option>Excluded</option>
                      </select> */}
                      <div className="gst-box">
                      <span className="gst-span">
                          <input id="nogst" type='radio' name="gst" value='no'
                            onClick={() => setGstType('no')} checked={gstType==='no'} />
                        <label htmlFor="nogst">No</label>
                      </span>
                      <span className="gst-span">
                        <input id="included" type='radio' name="gst" value='included'
                          onClick={() => setGstType('included')} checked={gstType==='included'} />
                        <label htmlFor="included">Included</label>
                      </span>
                      <span className="gst-span">
                          <input id="excluded" type='radio' name="gst" value='excluded'
                            onClick={() => setGstType('excluded')} checked={gstType==='excluded'} />
                        <label htmlFor="excluded">Excluded</label>
                        </span>
                      </div>
                    </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"><b>Stripe Secret Key:</b></label>
                        <input value={stripeKey} onChange={(e)=>setStripeKey(e.target.value)} type="key" placeholder="Enter Stripe Secret Key" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"><b>DocuSign Client ID:</b></label>
                        <input value={dsClientID} onChange={(e)=>setDsClientID(e.target.value)} type="key" placeholder="Enter DocuSign Client ID" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1"><b>DocuSign Client Secret:</b></label>
                        <input value={dsClientSecret} onChange={(e)=>setDsClientSecret(e.target.value)} type="key" placeholder="Enter DocuSign Client Secret" className="input1" id="exampleInputEmail1" aria-describedby="emailHelp" />
                      </div>

                      <div className="form-group color">
                        <label className='mr-3' htmlFor="color"><b>Invoice Theme Color:</b></label>
                        <input value={themeColor} onChange={(e) => setThemeColor(e.target.value)} type="color"
                           id="color" aria-describedby="emailHelp" />
                      </div>
                    <div className="form-group from-group1">
                      <label htmlFor="exampleFormControlTextarea1"><b>Invoice Footer:</b></label>
                      <textarea value={invoiceFooter} onChange={(e) => setInvoiceFooter(e.target.value)}
                        placeholder="Enter footer details here" className="form-control" id="exampleFormControlTextarea1" rows={3}/>
                    </div>
                    <div className="form-group from-group1">
                      <label htmlFor="exampleFormControlTextarea1"><b>Email Template:</b></label>
                      <textarea value={emailTemplate} onChange={(e) => setEmailTemplate(e.target.value)}
                        placeholder="Enter email text here" className="form-control" id="exampleFormControlTextarea1" rows={3} />
                    </div>
                    <div className="form-group from-group1">
                      <label style={{ marginTop: "50px" }} htmlFor="exampleFormControlTextarea1"><b>SMS Template:</b></label>
                      <textarea value={smsTemplate} onChange={(e) => setSmsTemplate(e.target.value)}
                        placeholder="Enter sms text here" className="form-control" id="exampleFormControlTextarea1" rows={3} />
                    </div>
                    <button type="submit" className="btn btn-primary">Update</button>
                  </form>
                </div>
              </div>
              </div>
            </div>
            <div className="page_single layout_fullwidth_padding">
                 
              <div className="contactform" id="dvform">
                {/* Add form here */}
              </div>
            </div>
          </div>
          : <></>}
      </>
    );
  }

export default ConfigurationScreen;