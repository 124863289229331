import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceList, newInvoice, selectedServicesList } from "../actions/invoiceActions";
import Header from "../components/Header";
import Loadingbox from "../components/LoadingBox";
import { INVOICE_SAVE_RESET } from "../constants/invoiceConstants";

function InvoiceDetailsScreen(props) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, pinCodeLogin } = userSignin; 
  
  const choosenClientInfo = useSelector((state) => state.choosenClientInfo);
  const { choosenClient } = choosenClientInfo;

  // let choosenClient= localStorage.getItem('choosenClient')
  
  const settingsInfo = useSelector((state) => state.settingsInfo);
  const { settings } = settingsInfo;
  
  const invoiceInfo = useSelector(state => state.invoiceInfo);
  const { selectedServices } = invoiceInfo;
  
  const servicesInfo = useSelector((state) => state.serviceInfo);
  
  const formTypeInfo = useSelector((state) => state.formInfo.formType);
  const date = useSelector((state) => state.currentDate.date);
  console.log("curr", date);

  const [selectedClient, setSelectedClient] = useState(choosenClient);

  const [longDescription, setLongDescription] = useState('');
  const [qty, setQty] = useState('');
  const [price, setPrice] = useState('');
  const [choosenService, setChoosenService] = useState(false);
  const [serviceType, setServiceType] = useState('manualService');
  const [formType] = useState(formTypeInfo?formTypeInfo:'');

  
  const [serviceIndex, setServiceIndex] = useState(0);
  const [selectedService, setSelectedService] = useState({
    shortDescription: '',
    longDescription: '',
    qty:1,
    price: 0,
    itemNumber:0
  });
  const [itemNumber, setItemNumber] = useState(0);
  const [listItems, setListItems] = useState(selectedServices?selectedServices:[]);


  const dispatch = useDispatch();

  // let totalPrice = 0;
  let subTotal = listItems.reduce((prev, next) => prev + next.price*next.qty, 0);
  console.log("total", subTotal)
  let gst = settings.gstType === 'no' ? 0 :
    settings.gstType === 'included' ? subTotal / 11 :
      settings.gstType === 'excluded' ? subTotal / 10 : 0;
  let totalAmount = settings.gstType === 'no' ? subTotal :
    settings.gstType === 'included' ? subTotal :
  settings.gstType === 'excluded' ? subTotal+gst : subTotal;
  let paidAmount = 0;
  let balanceAmount = totalAmount - paidAmount;
  
  useEffect(() => {
    if (!pinCodeLogin) {
      props.history.push('/');
    }
    }, [props.history, userInfo, pinCodeLogin]);

  useEffect(() => {
    dispatch({
      type: INVOICE_SAVE_RESET,
    });
  }, [dispatch]);

  useEffect(() => {
    if(choosenClient){
      setSelectedClient(choosenClient);
    }
  }, [choosenClient]);
  
  useEffect(() => {
    dispatch(serviceList());
  }, [dispatch]);

  useEffect(() => {
    if (serviceType === 'manualService') {
      // setChoosenService(true);
      setSelectedService({
        longDescription: '',
        shortDescription: 'Manual Service',
        price: '',
        qty: ''
      });
      setQty('');
    } else if(serviceType==='existingService'){
      setChoosenService(false);
    }
  }, [serviceType]);

  useEffect(() => {
    if (serviceType === 'manualService') {

    } else if (serviceType === 'existingService') {
      setTimeout(() => {
        
        setSelectedService(servicesInfo.servicesList[serviceIndex]);
      },300)
    }
  }, [serviceIndex]);

  useEffect(() => {
    if (selectedService) {
      if (serviceType === 'manualService') {
        setLongDescription(selectedService && selectedService.longDescription);
        setPrice(selectedService && selectedService.price);
        // setQty('');
      } else if (serviceType === 'existingService') {
        setLongDescription(selectedService.longDescription);
        setPrice(selectedService.price);
      }

      // setQty(1);
      // setTimeout(() => {
      //   setSelectedService({
      //   ...selectedService,
      // })
      // },1000)
    } else {
      setChoosenService(false);
    }
  }, [selectedService]);

  const longDescriptionHandler = (value) => {
    if (serviceType === 'manualService') {
      console.log("val==>", value.replaceAll('\n', '<br />'))
      setSelectedService({
        ...selectedService,
        longDescription: value
      });
    if (value === '') {
      setChoosenService(false);
      } else {
      setChoosenService(true);
      }
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        longDescription: value
      })
    }
  };

  useEffect(() => {
    if (serviceType === 'manualService') {
      // console.log("val", value)
      setSelectedService({
        ...selectedService,
        qty
      })
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        qty
      })
    }
  }, [qty]);

  const priceHandler = (value) => {
    if (serviceType === 'manualService') {
      // console.log("val", value)
      setSelectedService({
        ...selectedService,
        price: value
      })
    } else if (serviceType === 'existingService') {
      setSelectedService({
        ...selectedService,
        price: value
      })
    }
  };

  const addToList = () => {
    if (serviceType === 'existingService' && !choosenService) {
      alert('Please select service form the list');
    } else if (serviceType === 'existingService' && selectedService.longDescription==='') {
      alert('Please enter description');
    } else if (serviceType === 'manualService' && selectedService.longDescription==='') {
      alert('Please enter description');
      
    } else{
      
      setItemNumber(itemNumber + 1)
      // const tempService = servicesInfo.servicesList[serviceIndex];
      setListItems(prevArray => [...prevArray, {
        shortDescription: selectedService.shortDescription,
        longDescription: selectedService.longDescription,
        qty: qty,
        price: selectedService.price,
        itemNumber: itemNumber
      }]);
      if (serviceType === 'existingService') {
        setChoosenService(false);
        let select = document.getElementById('selectBox');
        select.selectedIndex = 0;
      } else {
        setSelectedService({
          longDescription: '',
          shortDescription: '',
          qty: '',
          price: ''
        });
        setQty('');
      }
      // setShortDescription('Please select invoice service');
    }
  }

  // useEffect(() => {
  //   if (!pinCodeLogin) {
  //     props.history.push('/');
  //   }
  //   }, [props.history, userInfo, pinCodeLogin]);

  // useEffect(() => {
  //   dispatch(serviceList());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (serviceType === 'manualService') {
  //   setLongDescription('');
  //   setQty('');
  //   setPrice('');
  //   } else if (servicesList && serviceType === 'existingService') {
  //     setSelectedService(servicesList[serviceIndex])
  //   }

  // }, [serviceType, servicesList]);

  // useEffect(() => {
  //   if (!choosenClient && !selectedClient.loading) {
  //     props.history.push('/create');
  //   }
  // }, [props.history, choosenClient,selectedClient]);

  // useEffect(() => {
  //   if (servicesList) {
  //     setSelectedService(servicesList[serviceIndex]);
  //   }

  // }, [serviceIndex, servicesList]);

  // useEffect(() => {
  //   if (choosenService) {
  //     setSelectedService(servicesList[serviceIndex]);
  //   }
  // }, [choosenService])

  // useEffect(() => {
  //   setChoosenService(false);
  // }, [serviceType])
  
  // useEffect(() => {
  //   if (selectedService && serviceType==='existingService') {
  //     setLongDescription(selectedService.longDescription);
  //     setPrice(selectedService.price)
  //   }

  // }, [serviceIndex, selectedService]);
  
  // useEffect(() => {
  //   tempService = selectedService;
  //   tempService.longDescription = longDescription;
  //   setSelectedService(tempService)
  // }, [longDescription]);

  // useEffect(() => {
  //   tempService = selectedService;
  //   if (typeof price === 'number'){
  //     tempService.price = price;
  //   } else {
  //     tempService.price = 0;
  //   }
  //   setSelectedService(tempService)
  // }, [price]);

  // const addToList = () => {
  //   setItemNumber(itemNumber+1)
  //   const tempService = servicesList[serviceIndex];
  //   if (serviceType === 'existingService') {
  //     setListItems(prevArray => [...prevArray, {
  //       shortDescription: tempService.shortDescription,
  //       longDescription: tempService.longDescription,
  //       qty: qty,
  //       price: parseFloat(tempService.price),
  //       itemNumber: itemNumber
  //     }]);
  //     // setServiceIndex(null);
  //   } else if (serviceType === 'manualService') {
  //     setListItems(prevArray => [...prevArray, {
  //       shortDescription,
  //       longDescription,
  //       qty: qty,
  //       price: parseFloat(price),
  //       itemNumber: itemNumber
  //     }]);
  //   }
  //   setChoosenService(false);
  //   let select = document.getElementById('selectBox');
  //   if(select){
  //     select.selectedIndex = 0;
  //   }
  //   if (serviceType === 'manualService') {
  //     setLongDescription('');
  //     setQty('');
  //     setPrice('');
  //   } else {
      
  //   }
  // }



  const removeFromList = (index) => {
    const removedItem = listItems.splice(index,1);
    setListItems(listItems.filter(x=>x.itemNumber!==removedItem[0].itemNumber));

  }

  const [invoice] = useState(formType === 'Invoice' ? 'Yes' : 'No');
  const [quotation] = useState(formType === 'Quotation' ? 'Yes' : 'No');
  const [receipt] = useState(formType === 'Receipt' ? 'Yes' : 'No');

  const submitHandler = () => {
    dispatch(selectedServicesList(listItems));
    
    // const date = GetFormattedDate();

    const generatedInvoice = {
      type: formType,
      quotation,
      invoice,
      receipt,
      clientId: selectedClient._id,
      clientName: selectedClient.clientName,
      companyName: selectedClient.companyName,
      email: selectedClient.email,
      phone: selectedClient.phone,
      address: selectedClient.address,
      ABN: selectedClient.ABN,
      listItems: listItems,
      gstType: settings.gstType ? settings.gstType : 'no',
      subTotal,
      totalAmount,
      gst,
      paidAmount,
      balanceAmount,
      invoiceDate: formType === 'Invoice' ? date : '',
      quotationDate: formType === 'Quotation' ? date : '',
      receiptDate: formType === 'Receipt' ? date : '',
    };
    
    dispatch(newInvoice(generatedInvoice));
    // dispatch(invoiceList());

  }

  if (invoiceInfo.success) {
    props.history.push('/generate');
        setTimeout(() => {
      dispatch({
        type: INVOICE_SAVE_RESET,
      }); 
    }, 2000);
    
  }
    const GetFormattedDate=()=> {
    var todayTime = new Date();
    var month = todayTime.getMonth()+1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    return day + "/" + month + "/" + year;
  }
  // console.log("Temp Service==>", tempService);
  // console.log("Services ==>", servicesList);
  console.log("Seleced Service==>", selectedService);
  console.log("List Items==>", listItems);
  // console.log("service type==>", serviceType);
  // console.log("long Description==>", longDescription);
  // console.log("QTY==>", qty);
  // console.log("Amount==>", price);
    return (
      <>

        <Header />
      
        <div id="pages_maincontent">

          <h2 className="page_title" >
            <i onClick={props.history.goBack} className="fa fa-chevron-left back-ico"
              aria-hidden="true"></i>
            <i className="fa fa-plus" aria-hidden="true" />           
              &nbsp;Create {formType}
          </h2>
          
          <h3 id="dvResellerName" >
            Enter {formType} details here
          </h3>

          <div className="generate-icon">
            <i className="fas fa-file-invoice" onClick={() => submitHandler()}></i>          
          </div>
          
          <div className="page_single layout_fullwidth_padding">
            {invoiceInfo.loading && <Loadingbox>Generating Invoice, please wait...</Loadingbox>}
            <>
            {selectedClient ? 
              <div className="client-details">
                {selectedClient.clientName ?
                  <div>
                    <label>Name :</label>
                    <p>{selectedClient.clientName}
                      {selectedClient.companyName ? ` ( ${selectedClient.companyName} )`: ''}</p>
                    </div> : <></>}
                  {selectedClient.address ?
                  <div>
                    <label>Address :</label>
                    <p>{selectedClient.address}</p>
                  </div> : <></>}
                {selectedClient.email ?
                  <div>
                    <label>Email :</label>
                    <p>{selectedClient.email}</p>
                  </div> : <></>}
                {selectedClient.phone ?
                  <div>
                    <label>Phone :</label>
                    <p>{selectedClient.phone}</p>
                  </div> : <></>}
                {selectedClient.ABN ?
                  <div>
                    <label>ABN :</label>
                    <p>{selectedClient.ABN}</p>
                  </div> : <></>}
       
              </div> : <></>}
            
          
            {listItems.length !== 0 ?
              <ul className="list">                
                {listItems.map((v, i) =>               
                  <li key={i}>                   
                    <div className="details">                     
                      {/* <div>
                        <b>{v.shortDescription}</b>
                      </div> */}
                    
                      <div dangerouslySetInnerHTML={{__html: `${v.longDescription.replaceAll(/\n/g, '<br/>')}`}}>
                        {/* {v.longDescription} <b>( $ {parseFloat(v.price).toFixed(2)} x {v.qty})</b> */}
                        {/* {v.longDescription.replaceAll(/\n/g, '<br/>')} {Number(v.price) && v.price!==0?<b>( $ {parseFloat(v.price).toFixed(2)} x {v.qty})</b>:''} */}
                        {/* test<br />test1 */}
                      </div>
            
                    </div>
                
                    <div className="actions">
                      <i onClick={() => removeFromList(i)}       
                        className="fa fa-trash" aria-hidden="true" />
                    </div>
                  </li> 
                )}
              </ul> : <></>}
            
            <div className="contactform" id="dvform">
              
              {/* <div className="form_row">
                <label htmlFor="Select Client">{formType} Service: </label>               
                <select id="selectBox" onChange={(e) => {
                  setServiceIndex(e.target.value);
                  setChoosenService(true);
                  setQty(1);
                }}
                  className="form-select form-select-sm" aria-label=".form-select-sm example">                 
                  <option defaultValue>Please select service</option>                 
                  {servicesInfo.servicesList ? <>                   
                    {servicesInfo.servicesList.map((v, i) =>                           
                      <option key={i} id={i} value={i}>{v.shortDescription}</option>
                    )}                    
                  </>                   
                    : <></>}                 
                </select>                
              </div> */}
                
              <div className="form_row">
                {/* <label htmlFor="Select Client">Service Type: </label>                */}
                {/* <select id="selectBox" onChange={(e) => {
                    setServiceType(e.target.value);
                    setChoosenService(false);
                }}
                  className="form-select form-select-sm" aria-label=".form-select-sm example">                 
                  <option defaultValue>Please select service type</option>                                 
                      <option value='existingService'>Service from list</option>
                      <option value='manualService'>Manual Service</option>              
                </select>                 */}
                  {servicesInfo.servicesList && servicesInfo.servicesList.length < 1 ?<></>:
                    <div className="radio-options">
                      <div className="radio">
                        <input defaultChecked type="radio" id="manualService" name="service" value="manualService"
                          onChange={(e) => setServiceType(e.target.value)} />
                        <label htmlFor="manualService">Manual Service</label>
                      </div>
                      <div className="radio">
                        <input disabled={servicesInfo.servicesList && servicesInfo.servicesList.length < 1}
                          type="radio" id="existingService" name="service" value="existingService"
                          onChange={(e) => setServiceType(e.target.value)} />
                        <label className={servicesInfo.servicesList && servicesInfo.servicesList.length < 1 ? 'disabled' : ''}
                          htmlFor="existingService">Services from list</label>
                      </div>
                    </div> }
              </div>
              
                {serviceType === 'existingService' ?<>
                  <div className="form_row">
                    <label htmlFor="Select Client">{formType} Service: </label>
                    <select id="selectBox" onChange={(e) => {
                      setServiceIndex(e.target.value);
                      setChoosenService(true);
                      setQty(1);
                    }}
                      className="form-select form-select-sm" aria-label=".form-select-sm example">
                      <option defaultValue>Please select service</option>
                      {servicesInfo.servicesList ? <>
                        {servicesInfo.servicesList.map((v, i) =>
                          <option key={i} id={i} value={i}>{v.shortDescription}</option>
                        )}
                      </>
                        : <></>}
                    </select>
                  </div>
                {choosenService ?
                <>
                  <div className="form_row">
                    <label htmlFor="description">Description: </label>
                    <textarea disabled={!choosenService} onChange={(e) => longDescriptionHandler(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter description" />
                  </div>

                  <div className="form_row">
                    {choosenService ? <>
                      <i className="fas fa-plus-square" onClick={() => setQty(Number(qty) + 1)}></i>
                      {qty >= 2 ?
                        <i className="fas fa-minus-square" onClick={() => setQty(Number(qty) - 1)}></i> :
                        <i className="fas fa-minus-square"></i>}
                    </> : <></>}
                    <label htmlFor="amount">Quantity: </label>
                    <input disabled={!choosenService} onChange={(e) => setQty(e.target.value)} value={qty}
                      type="number" name="qty" id="qty" placeholder="Enter Quantity"
                      className="form_input required" />
                  </div>
              
                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input disabled={!choosenService} onChange={(e) => priceHandler(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                  </div>
                </> : <></>}
                </> : serviceType === 'manualService' ?
                  <>
                  <div className="form_row">
                    <label htmlFor="description">Description: </label>
                    <textarea onChange={(e) => longDescriptionHandler(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter description" />
                  </div>

                  <div className="form_row">
                    <>
                      <i className="fas fa-plus-square" onClick={() => setQty(Number(qty) + 1)}></i>
                      {qty >= 2 ?
                        <i className="fas fa-minus-square" onClick={() => setQty(Number(qty) - 1)}></i> :
                        <i className="fas fa-minus-square"></i>}
                    </>
                    <label htmlFor="amount">Quantity: </label>
                    <input onChange={(e) => setQty(e.target.value)} value={qty}
                      type="number" name="qty" id="qty" placeholder="Enter Quantity"
                      className="form_input required" />
                  </div>
              
                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input onChange={(e) => priceHandler(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                  </div>
                </>:<></>}

              {/* {choosenService ?
                <>
                  <div className="form_row">
                    <label htmlFor="description">Description: </label>
                    <textarea disabled={!choosenService} onChange={(e) => setLongDescription(e.target.value)}
                      value={longDescription}
                      name="description" id="description" rows={10}
                      placeholder="Enter description" />
                  </div>

                  <div className="form_row">
                    {choosenService ? <>
                      <i className="fas fa-plus-square" onClick={() => setQty(Number(qty) + 1)}></i>
                      {qty >= 2 ?
                        <i className="fas fa-minus-square" onClick={() => setQty(Number(qty) - 1)}></i> :
                        <i className="fas fa-minus-square"></i>}
                    </> : <></>}
                    <label htmlFor="amount">Quantity: </label>
                    <input disabled={!choosenService} onChange={(e) => setQty(e.target.value)} value={qty}
                      type="number" name="qty" id="qty" placeholder="Enter Quantity"
                      className="form_input required" />
                  </div>
              
                  <div className="form_row">
                    <label htmlFor="amount">Amount: </label>
                    <input disabled={!choosenService} onChange={(e) => setPrice(e.target.value)} value={price}
                      type="number" name="amount" id="amount" placeholder="Enter Amount"
                      className="form_input required" />
                  </div>
                </> : <></>} */}
              
              <input onClick={() => addToList()} type="button" id="add"
                name="submit" className="form_submit" defaultValue="Add Item" />
              
            </div>           
            </>
          </div>         
        </div>
      </>
    );
  }
export default InvoiceDetailsScreen;